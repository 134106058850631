<template>
  <component :is="dynamicIcon" />
</template>

<script>
export default {
  name: 'DynamicIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
  },
  computed: {
    dynamicIcon() {
      return () => import(`/static/images/${this.icon}.svg`)
    },
  },
}
</script>
