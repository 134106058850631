import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
// import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import ecoplotsDesktops from './routes/ecoplots-desktops'
// import apps from './routes/apps'
// import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/index'
// import pages from './routes/pages'
// import chartsMaps from './routes/charts-maps'
// import formsTable from './routes/forms-tables'
// import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'map' } },
    ...ecoplotsDesktops,
    // ...apps,
    // ...dashboard,
    // ...pages,
    // ...chartsMaps,
    // ...formsTable,
    // ...uiElements,
    // ...others,
    { path: '*', redirect: { name: 'error' } },
  ],
})

router.beforeEach((to, from, next) => {
  let token = localStorage.getItem('django-authentication-token')
  let accept = localStorage.getItem('accept-pro-policy')
  if (to.name == 'forget-password'|| to.name == 'reset-password') {
    if (token) {
      return next({ name: 'map' })
    } else {
      return next()
    }
  }
  if (!token && to.name !== 'login' && to.name !== 'embed') {
    Vue.prototype.$fromRoute = to
    return next({ name: 'login' })
  } else if (token && accept !== 'true' && to.name !== 'policy' && to.name !== 'embed') {
    return next({ name: 'policy' })
  } else {
    return next()
  }
})

// router.beforeEach((to, _, next) => {
//   const isLoggedIn = isUserLoggedIn()

//   if (!canNavigate(to)) {
//     // Redirect to login if not logged in
//     if (!isLoggedIn) return next({ name: 'auth-login' })

//     // If logged in => not authorized
//     return next({ name: 'misc-not-authorized' })
//   }

//   // Redirect if logged in
//   if (to.meta.redirectIfLoggedIn && isLoggedIn) {
//     const userData = getUserData()
//     next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
//   }

//   return next()
// })

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
