const Big = require('big.js');
import * as math from 'mathjs';
const _ = require('lodash');

/** Convert string to number
 * empty string or white string => null | else => Number() */
export function convertNumber(string) {
  let trimed = string.trim();
  let res = undefined;
  if (trimed.length) {
    // Support for 'kilo', 'million', 'billion' number
    if (trimed[trimed.length - 1] === 'k') {
      res = Math.round(Number(trimed.slice(0, -1)) * 1000);
    } else if (trimed[trimed.length - 1] === 'M') {
      res = Math.round(Number(trimed.slice(0, -1)) * 1000 * 1000);
    } else if (trimed[trimed.length - 1] === 'B') {
      res = Math.round(Number(trimed.slice(0, -1)) * 1000 * 1000 * 1000);
    } else {
      res = Number(trimed);
    }
  } else {
    res = NaN;
  }
  return isNaN(res) ? null : res;
}

/** Convert number to acronym format */
export function shorter(num) {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  var si_negative = [
    { value: 0, symbol: '' },
    { value: -1e3, symbol: 'k' },
    { value: -1e6, symbol: 'M' },
    { value: -1e9, symbol: 'G' },
    { value: -1e12, symbol: 'T' },
    { value: -1e15, symbol: 'P' },
    { value: -1e18, symbol: 'E' },
  ];
  var rx = /\.0+$|^-?(\.[0-9]*[1-9])0+$/;
  var i;
  if (num >= 0) {
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
  } else {
    for (i = si_negative.length - 1; i > 0; i--) {
      if (num <= si_negative[i].value) {
        break;
      }
    }
  }
  return (num / si[i].value).toFixed(1).replace(rx, '$1') + si[i].symbol;
}

/** Convert number to string, if cannot convert then return 'NA' */
export function revertNumber(number) {
  if (typeof number !== 'number') return 'NA';
  return number.toString();
}

/** Generate a random number from min to max */
export function random(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/** Apply log10 to an array: log10(x) */
export function scaleLog10(scaleArray) {
  let results = scaleArray;
  for (let i = 0; i < results.length; i++) {
    if (results[i] === null) {
      continue;
    } else if (results[i] <= 0) {
      results[i] = null;
    } else {
      results[i] = Math.log10(results[i]);
    }
  }
  return results;
}

/** Apply log10(x+1) to an array: log10(x) */
export function scaleLog10Plus(scaleArray) {
  let results = scaleArray;
  for (let i = 0; i < results.length; i++) {
    if (results[i] === null) {
      continue;
    } else if (results[i] < 0) {
      results[i] = null;
    } else {
      results[i] = Math.log10(results[i] + 1);
    }
  }
  return results;
}

/** Apply normalize to an array: (x-min)/(max-min) */
export function scaleNormalize(scaleArray, minInput = null, maxInput = null) {
  let results = scaleArray;
  let notNullArray = results.filter((v) => v !== null || v !== undefined);
  if (!notNullArray.length) return [];
  let arrNotNullArray = _.cloneDeep(notNullArray);
  arrNotNullArray = arrNotNullArray.filter(Number);
  if (!arrNotNullArray.length) return [];
  let min = minInput || math.min(arrNotNullArray);
  let max = maxInput || math.max(arrNotNullArray);
  for (let i = 0; i < results.length; i++) {
    if (results[i] === null) {
      continue;
    } else if (min === max) {
      results[i] = 0;
    } else {
      results[i] = (results[i] - min) / (max - min);
    }
  }
  return results;
}

/** Apply standardize to an array: (x-μ)/σ */
export function scaleStandardize(scaleArray, meanInput = null, stdInput = null) {
  let results = scaleArray;
  let notNullArray = results.filter((v) => v !== null || v !== undefined);
  if (!notNullArray.length) return [];

  let arrNotNullArray = _.cloneDeep(notNullArray);
  arrNotNullArray = arrNotNullArray.filter(Number);
  if (!arrNotNullArray.length) return [];
  let mean = meanInput || math.mean(arrNotNullArray);
  let std = stdInput || math.std(arrNotNullArray);
  for (let i = 0; i < results.length; i++) {
    if (results[i] === null) {
      continue;
    } else if (std === 0) {
      results[i] = 0;
    } else {
      results[i] = (results[i] - mean) / std;
    }
  }
  return results;
}

/** Apply log10 to value: log10(x) */
export function scaleValueLog10(value) {
  if (value <= 0) value = null;
  else value = Math.log10(value);
  return value;
}

/** Apply log10(x+1) to value: log10(x) */
export function scaleValueLog10Plus(value) {
  if (value <= 0) value = null;
  else value = Math.log10(value + 1);
  return value;
}

/** Apply normalize to value: (x-min)/(max-min) */
export function scaleValueNormalize(value, min, max) {
  if (min === max) value = 0;
  else value = (value - min) / (max - min);
  return value;
}

/** Apply standardize to value: (x-μ)/σ */
export function scaleValueStandardize(value, mean, std) {
  if (std === 0) value = 0;
  else value = (value - mean) / std;
  return value;
}

/** Replace NA values with zero */
export function replaceNaWithZero(array) {
  return array.map((e) => (e === null || e === '' ? 0 : e));
}

/** Replace NA values with forward value */
export function replaceNaWithForwardValue(array) {
  for (let i = 0; i < array.length; i++) {
    if ((array[i] === null && i > 0) || (array[i] === '' && i > 0)) array[i] = array[i - 1];
  }
  return array;
}

/** Replace NA values with backward value */
export function replaceNaWithBackwardValue(array) {
  for (let i = array.length - 1; i >= 0; i--) {
    if ((array[i] === null && i < array.length - 1) || (array[i] === '' && i < array.length - 1)) array[i] = array[i + 1];
  }
  return array;
}

/** Replace NA values with backward value */
export function replaceNaWithLinearInterpolation(array) {
  let naPositionsStart = [];
  let naPositionsEnd = [];
  for (let i = 0; i < array.length; i++) {
    if ((array[i] !== null && array[i + 1] === null && i < array.length - 1) || (array[i] !== '' && array[i + 1] === '' && i < array.length - 1)) naPositionsStart.push(i);
    else if ((array[i] === null && array[i + 1] !== null && i < array.length - 1 && naPositionsStart.length - naPositionsEnd.length === 1) || (array[i] !== '' && array[i + 1] === '' && i < array.length - 1 && naPositionsStart.length - naPositionsEnd.length === 1)) naPositionsEnd.push(i + 1);
  }
  for (let i = 0; i < naPositionsStart.length; i++) {
    let interpolate = interpolateArray([array[naPositionsStart[i]], array[naPositionsEnd[i]]], naPositionsEnd[i] + 1 - naPositionsStart[i]);
    for (let j = 0; j < interpolate.length; j++) {
      array[j + naPositionsStart[i]] = interpolate[j];
    }
  }

  return array;
}

export function interpolateArray(originArray, fitCount) {
  let result = [];
  let springFactor = (originArray.length - 1) / (fitCount - 1);
  try {
    springFactor = Number(Big(originArray.length - 1).div(fitCount - 1));
  } catch(e) {}

  result[0] = originArray[0];
  for (let i = 1; i < fitCount - 1; i++) {
    const tmp = i * springFactor;

    const before = Math.floor(tmp).toFixed();
    const after = Math.ceil(tmp).toFixed();
    const atPoint = tmp - before;
    result[i] = linearInterpolate(originArray[before], originArray[after], atPoint);
  }
  result[fitCount - 1] = originArray[originArray.length - 1]; // for new allocation
  return result;
}

export function linearInterpolate(before, after, atPoint) {
  let result = before + (after - before) * atPoint;
  try {
    let beforeTemp= new Big(Number(before));
    let afterTemp= new Big(Number(after));
    let atPointTemp= new Big(Number(atPoint));
    result = Number(beforeTemp.plus(afterTemp.minus(beforeTemp).times(atPointTemp)));
  } catch (e){}
  return result;
}

export function interpolateArraywithLinear(originArray, fitCount) {
  let result = _.cloneDeep(originArray);
  try {
    let lengthNA = 0;
    for (let i = 0; i < result.length; i++) {
      if (result[i] == null) {
        lengthNA++;
      } else {
        if (i < lengthNA + 1) {
          lengthNA = 0;
          continue;
        } else {
          if (lengthNA) {
            let createLinear = [];
            const springFactor = (result[i] - result[i - lengthNA - 1]) / (lengthNA + 1);
            for (let j = 1; j <= lengthNA; j++) {
              createLinear.push(result[i - lengthNA - 1] + j * springFactor);
            }
            result.splice(i - lengthNA, lengthNA, ...createLinear);
          }
        }
      }
    }
    if (originArray.length < fitCount) {
      for (let i = 1; i <= fitCount - originArray.length; i++) {
        result.push(null);
      }
    }
    return result;
  } catch {
    return result;
  }
}
