const _ = require('lodash');
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    layers: [],
    verticalLines: [],
    horizontalLines: [],
    cellNumberX: 0,
    cellNumberY: 0,
    updateDataNetCDF: 0,
    isVisible: {},
    isVisibleCount: 0,
    groups: [],
    layerChangePosition: false,
    netcdfLoadingImg: '',
    changeNetcdfCache: 0,
    getImagePos: 0,
    toggleVisibleCount: 0,
    sceneUpdate: false,
  },
  mutations: {
    ADD_LAYER(state, payload) {
      state.layers.push(payload);
      state.isVisible[payload.id] = true;
    },
    UPDATE_LAYER(state, payload) {
      let index = state.layers.findIndex((l) => l.id === payload.id);

      if (index === -1) return;
      if (payload.sceneUpdate) state.sceneUpdate = true;
      else state.sceneUpdate = false;
      let oldLayer = state.layers[index];
      let newLayer = _.mergeWith({}, oldLayer, payload, { version: oldLayer.version + 1 }, (objValue, srcValue, key, object, source, stack) => {
        if (key === 'levels') {
          return _.cloneDeep(srcValue);
        }
        if (key === 'thresholds') {
          return _.cloneDeep(srcValue);
        }
      });

      Vue.set(state.layers, index, newLayer);
    },
    UPDATE_LAYER_WITH_PRERENDER_NETCDF(state, payload) {
      let index = state.layers.findIndex((l) => l.id === payload.id && l.type == 'netcdf');
      if (index === -1) return;
      state.layers[index].netcdf.netcdfCache = payload.cache;
    },
    CHECK_VISIBLE_SCENE(state, payload) {
      let index = state.layers.findIndex((l) => l.id === payload.id);
      if (index === -1) return;
      for (let i = 0; i < 2; i++) {
        let oldVisible = state.layers[index].visible[i];
        let newVisible = payload.visible[i];
        if (newVisible !== oldVisible) Vue.set(state.layers[index].visible, i, newVisible);
        state.toggleVisibleCount++;
      }
    },
    /** SIMILAR TO update_layer BUT NOT INCREASE VERSION */
    UPDATE_LEGEND_POSITION(state, payload) {
      let index = state.layers.findIndex((l) => l.id === payload.id);
      if (index === -1) return;

      let layer = state.layers[index];
      layer.legend.position = payload.legend.position;
    },
    MERGE_LAYER(state, payload) {
      let index = state.layers.findIndex((l) => l.id === payload.id);

      if (index === -1) return;

      let oldLayer = state.layers[index];

      let newLayer = _.mergeWith({}, oldLayer, payload, (objValue, srcValue, key, object, source, stack) => {
        if (key === 'levels') {
          return _.cloneDeep(srcValue);
        }
        if (key === 'thresholds') {
          return _.cloneDeep(srcValue);
        }
      });
      Vue.set(state.layers, index, newLayer);
    },
    DELETE_LAYER(state, payload) {
      state.layers = state.layers.filter((d) => d.id !== payload);
    },
    TOGGLE_VISIBLE(state, { layerId, mapIndex }) {
      let index = state.layers.findIndex((l) => l.id === layerId);

      if (index === -1) return;

      let oldVisible = state.layers[index].visible[mapIndex];

      Vue.set(state.layers[index].visible, mapIndex, !oldVisible);
      state.toggleVisibleCount++;
    },

    INSPECT_LAYER(state, { layerId }) {
      let index = state.layers.findIndex((l) => l.id === layerId);

      if (index === -1) return;

      let oldLayer = state.layers[index];

      Vue.set(state.layers, index, _.merge({}, oldLayer, { inspectCount: oldLayer.inspectCount + 1 }));
    },
    CLEAR_LAYER(state) {
      state.layers = [];
      state.groups = [];
    },
    SET_LAYERS(state, payload) {
      state.layers = payload;
    },

    SET_NETCDF_LINES(state, value) {
      state.verticalLines = value.verticalLines;
      state.horizontalLines = value.horizontalLines;
    },
    SET_NETCDF_GRID_SIZE(state, value) {
      state.cellNumberX = value.cellNumberX;
      state.cellNumberY = value.cellNumberY;
    },
    SET_DATA_NETCDF(state) {
      state.updateDataNetCDF++;
    },
    VISIBLE_LAYER(state, value) {
      state.isVisible[value.id] = value.flag;
      state.isVisibleCount++;
    },
    ADD_GROUP(state, payload) {
      state.groups.push(payload);
    },
    SET_GROUPS(state, payload) {
      state.groups = payload;
    },
    UPDATE_NAME_GROUPS(state, payload) {
      let index = state.groups.findIndex((l) => l.id === payload.id);
      if (index === -1) return;
      state.groups[index].name = payload.name;
    },
    DELETE_GROUP(state, payload) {
      state.groups = state.groups.filter((d) => d.id !== payload);
    },
    SET_STATUS_LAYER_MOVE(state, value) {
      state.layerChangePosition = value;
    },
    UPDATE_GROUP(state, payload) {
      let index = state.groups.findIndex((l) => l.id === payload.id);

      if (index === -1) return;

      let oldLayer = state.groups[index];

      let newLayer = _.mergeWith({}, oldLayer, payload, { version: oldLayer.version + 1 }, (objValue, srcValue, key, object, source, stack) => {
        if (key === 'levels') {
          return _.cloneDeep(srcValue);
        }
        if (key === 'thresholds') {
          return _.cloneDeep(srcValue);
        }
      });

      Vue.set(state.groups, index, newLayer);
    },
    SET_NETCDF_LOADING_IMG(state, value) {
      state.netcdfLoadingImg = value;
    },
    SET_NETCDF_CACHE(state, payload) {
      let layers = _.cloneDeep(state.layers);
      if (payload.value <= -1 || !layers || layers.length <= 0) return;
      let idLayerCommit = layers.findIndex((layer) => layer.id === payload.id);
      if (idLayerCommit < 0) return;
      state.layers[idLayerCommit].netcdf.netcdfCache.push(payload.value);
      state.changeNetcdfCache++;
    },
    CLEAR_NETCDF_CACHE(state, payload) {
      let layers = _.cloneDeep(state.layers);
      let idLayerCommit = layers.findIndex((layer) => layer.id === payload.id);
      if (idLayerCommit < 0) return;
      state.layers[idLayerCommit].netcdf.netcdfCache = [];
    },
    CLEAR_NETCDF_CACHE_AND_METADATA(state, payload) {
      let layers = _.cloneDeep(state.layers);
      let idLayerCommit = layers.findIndex((layer) => layer.id === payload.id);
      if (idLayerCommit < 0) return;
      state.layers[idLayerCommit].netcdf.netcdfCache = [];
    },
    SET_IMAGE_POSITION(state, payload) {
      let index = state.groups.findIndex((l) => l.id === payload.id);
      if (index === -1) return;
      state.groups[index].image.corners = payload.corners;
    },
    GET_IMAGE_POSITION(state) {
      state.getImagePos++;
    },
    RESET_STATE(state) {
      state.layers = [];
      state.verticalLines = [];
      state.horizontalLines = [];
      state.cellNumberX = 0;
      state.cellNumberY = 0;
      state.updateDataNetCDF = 0;
      state.isVisible = {};
      state.isVisibleCount = 0;
      state.groups = [];
      state.layerChangePosition = false;
      state.netcdfCache = [];
      state.changeNetcdfCache = 0;
      state.getImagePos = 0;
      state.toggleVisibleCount = 0;
    },
    UPDATE_LAYER_FROM_COPYPASTE(state, layer) {
      try {
        let layers = state.layers;
        let indexFind = layers.findIndex((data) => data.id == layer.id);
        if (indexFind < 0) return;
        layers[indexFind] = _.merge(layers[indexFind], layer);
      } catch {}
    },
  },
};
