const DEFAULT_MAX_DECIMAL = 3;
const AUTO_MAX_NUMBER = 12;
const AUTO_MAX_DECIMAL = 3;
const DEFAULT_MARKER_SYMBOL = [0, 5, 25, 20];
export default {
  namespaced: true,
  state: {
    // Format
    defaultMaxDecimal: DEFAULT_MAX_DECIMAL,
    maxDecimal: DEFAULT_MAX_DECIMAL,
    decimalAutoFormat: true,
    autoMaxNumber: AUTO_MAX_NUMBER,
    autoMaxDecimal: AUTO_MAX_DECIMAL,
    // Chart
    lineColor: '#ff9439',
    backgroundColor: '#161d31',
    chartColor: '#397bff',
    chartFontFamily: 'Noto Sans JP',
    chartTextColor: null,
    chartTextSize: 14,
    yAxisRange: 'normalize',
    chartAddYear: false,
    legend: true,
    markerSymbol: DEFAULT_MARKER_SYMBOL,
    markerSymbolText: ['0', '1', '5', '4'],
    // calendar
    calendarPosition: 'TR',
    calendarShow: true,
    minimap: true,
    calendarUnit: 'auto',
    zoomSnap: 1,
    dataFilter: [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: [] }],
    countMinimap: 0,

    //map
    groupLegend: false,
    liveModeRefresh: 5000,
    countLiveMode: 0,
  },
  getters: {
    chart(state) {
      return {
        chartFontFamily: state.chartFontFamily,
        chartTextColor: state.chartTextColor,
        chartTextSize: state.chartTextSize,
      };
    },
  },
  mutations: {
    UPDATE_SETTINGS(state, { dataFilter, maxDecimal, decimalAutoFormat, lineColor, backgroundColor, chartColor, chartFontFamily, chartTextColor, chartAddYear, legend, markerSymbol, markerSymbolText, chartTextSize, yAxisRange, calendarPosition, calendarShow, calendarUnit, minimap, zoomSnap, groupLegend, liveModeRefresh }) {
      if (maxDecimal !== undefined) state.maxDecimal = maxDecimal;
      if (decimalAutoFormat !== undefined) state.decimalAutoFormat = decimalAutoFormat;
      if (lineColor !== undefined) state.lineColor = lineColor;
      if (backgroundColor !== undefined) state.backgroundColor = backgroundColor;
      if (chartColor !== undefined) state.chartColor = chartColor;
      if (chartFontFamily !== undefined) state.chartFontFamily = chartFontFamily;
      if (chartTextColor !== undefined) state.chartTextColor = chartTextColor;
      if (chartTextSize !== undefined) state.chartTextSize = chartTextSize;
      if (yAxisRange !== undefined) state.yAxisRange = yAxisRange;
      if (chartAddYear !== undefined) state.chartAddYear = chartAddYear;
      if (legend !== undefined) state.legend = legend;
      if (markerSymbol !== undefined) state.markerSymbol = markerSymbol;
      if (markerSymbolText !== undefined) state.markerSymbolText = markerSymbolText;
      if (calendarPosition !== undefined) state.calendarPosition = calendarPosition;
      if (calendarShow !== undefined) state.calendarShow = calendarShow;
      if (minimap !== undefined) state.minimap = minimap;
      if (zoomSnap !== undefined) state.zoomSnap = zoomSnap;
      if (calendarUnit !== undefined) state.calendarUnit = calendarUnit;
      if (dataFilter !== undefined) state.dataFilter = dataFilter;
      if (groupLegend !== undefined) state.groupLegend = groupLegend;
      if (liveModeRefresh !== undefined) state.liveModeRefresh = liveModeRefresh;
    },
    RESET_STATE(state) {
      // Format
      state.defaultMaxDecimal = DEFAULT_MAX_DECIMAL;
      state.maxDecimal = DEFAULT_MAX_DECIMAL;
      state.decimalAutoFormat = true;
      state.autoMaxNumber = AUTO_MAX_NUMBER;
      state.autoMaxDecimal = AUTO_MAX_DECIMAL;
      // Chart
      state.lineColor = '#ff9439';
      state.backgroundColor = '#161d31';
      state.chartColor = '#397bff';
      state.chartFontFamily = 'Noto Sans JP';
      state.chartTextColor = null;
      state.chartTextSize = 14;
      state.yAxisRange = 'normalize';
      state.chartAddYear = false;
      state.legend = true;
      state.markerSymbol = DEFAULT_MARKER_SYMBOL;
      state.markerSymbolText = ['0', '1', '5', '4'];
      // calendar
      state.calendarPosition = 'TR';
      state.calendarShow = true;
      state.minimap = true;
      state.calendarUnit = 'auto';
      state.zoomSnap = 1;
      state.dataFilter = [{ id: 0, text: 'metadata', value: 'Metadata', icon: '', opened: false, selected: true, disabled: false, loading: false, fixed: true, children: [] }];
      // map
      state.groupLegend = false;
      state.liveModeRefresh = 5000;
    },
    SET_FILTER_FILTER(state, payload) {
      state.dataFilter = payload.dataFilter;
    },
    SET_COUNT_MINIMAP(state) {
      state.countMinimap++;
    },
    SET_COUNT_LIVEMODE(state) {
      state.countLiveMode++;
    },
  },
};
