export default [
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/ecoplot-desktops/tabs/map/Map.vue'),
  },
  {
    path: '/timeseries',
    name: 'timeseries',
    component: () => import('@/views/ecoplot-desktops/tabs/timeseries/Timeseries.vue'),
  },
  {
    path: '/bar',
    name: 'bar',
    component: () => import('@/views/ecoplot-desktops/tabs/bar/Bar.vue'),
  },
  {
    path: '/lines',
    name: 'lines',
    component: () => import('@/views/ecoplot-desktops/tabs/line/Line.vue'),
  },
  {
    path: '/scatter',
    name: 'scatter',
    component: () => import('@/views/ecoplot-desktops/tabs/scatter/Scatter.vue'),
  },
  {
    path: '/boxplot',
    name: 'boxplot',
    component: () => import('@/views/ecoplot-desktops/tabs/boxplot/Boxplot.vue'),
  },
  {
    path: '/histogram',
    name: 'histogram',
    component: () => import('@/views/ecoplot-desktops/tabs/histogram/Histogram.vue'),
  },
  {
    path: '/decomposition',
    name: 'decomposition',
    component: () => import('@/views/ecoplot-desktops/tabs/decomposition/Decomposition.vue'),
  },
  {
    path: '/correlation',
    name: 'correlation',
    component: () => import('@/views/ecoplot-desktops/tabs/correlation/Correlation.vue'),
  },
  {
    path: '/machine-learning',
    name: 'machine-learning',
    component: () => import('@/views/ecoplot-desktops/tabs/machine-learning/MachineLearning.vue'),
  },
  {
    path: '/seasonal-analytics',
    name: 'seasonal-analytics',
    component: () => import('@/views/ecoplot-desktops/tabs/seasonal-analytics/SeasonalAnalytics.vue'),
  },
  {
    path: '/workerWindow',
    name: 'workerWindow',
    component: () => import('@/views/ecoplot-desktops/extra-windows/Worker.vue'),
  },
  // Authentication
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/ecoplot-desktops/authentication/Login.vue'),
    meta: { layout: 'full' },
  },
  // Embed
  {
    path: '/embed',
    name: 'embed',
    component: () => import('@/views/ecoplot-desktops/embed/Embed.vue'),
    meta: { layout: 'full' },
  },
  // {
  //   path: '/timeseries-test',
  //   name: 'timeseries-test',
  //   component: () => import('@/views/ecoplot-desktops/timeseries-test/Timeseries.vue'),
  // },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('@/views/ecoplot-desktops/projects/MyProjects.vue'),
  },
  {
    path: '/templates',
    name: 'templates',
    component: () => import('@/views/ecoplot-desktops/projects/PrefixTemplates.vue'),
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/ecoplot-desktops/authentication/Policy.vue'),
    meta: { layout: 'full' },
  },
  {
    path: '/scatter-threejs',
    name: 'scatter-threejs',
    component: () => import('@/views/ecoplot-desktops/tabs/scatter-threejs/Scatter.vue'),
  },
  {
    path: '/error',
    name: 'error',
    component: () => import('@/views/error/Error404.vue'),
    meta: { layout: 'full' },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ecoplot-desktops/profile/Profile.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/ecoplot-desktops/about/About.vue'),
  },
  // {
  //   path: '/aws-s3',
  //   name: 'aws-s3',
  //   component: () => import('@/views/ecoplot-desktops/navbar/aws-s3/AwsS3Manager.vue'),
  // },
  {
    path: '/datasource-tools',
    name: 'datasource-tools',
    component: () => import('@/views/ecoplot-desktops/tabs/datasource-tools/DatasourceTools.vue'),
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: () => import('@/views/ecoplot-desktops/authentication/Forget-password.vue'),
    meta: { layout: 'full' },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('@/views/ecoplot-desktops/authentication/Reset-password.vue'),
    meta: { layout: 'full' },
  },
  
]
