import Vue from 'vue'

// Feather Icon
import FeatherIcon from './@core/components/feather-icon/FeatherIcon.vue'
import DynamicIcon from './views/ecoplot-desktops/common/DynamicIcon.vue'
Vue.component(FeatherIcon.name, FeatherIcon);

// Vue Select
import VueSelect from 'vue-select'
Vue.component('VueSelect', VueSelect);


// #region Bootstrap Vue
// Layout
import { BContainer, BRow, BCol } from 'bootstrap-vue'
Vue.component('BContainer', BContainer);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);

// Common UI
import { BButton, BLink, BImg } from 'bootstrap-vue'
Vue.component('BButton', BButton);
Vue.component('BLink', BLink);
Vue.component('BImg', BImg);

// Advance UI
import { BBadge, BAvatar, BTooltip, BOverlay, BSpinner, BProgress, BModal, BPopover, BTab, BTabs, BCollapse, BPagination } from 'bootstrap-vue'
Vue.component('BBadge', BBadge);
Vue.component('BAvatar', BAvatar);
Vue.component('BTooltip', BTooltip);
Vue.component('BOverlay', BOverlay);
Vue.component('BSpinner', BSpinner);
Vue.component('BProgress', BProgress);
Vue.component('BModal', BModal);
Vue.component('BPopover', BPopover);
Vue.component('BTab', BTab);
Vue.component('BTabs', BTabs);
Vue.component('BCollapse', BCollapse);
Vue.component('BPagination', BPagination);

// Form
import { BForm, BFormInput, BFormGroup, BFormTextarea } from 'bootstrap-vue'
Vue.component('BForm', BForm);
Vue.component('BFormInput', BFormInput);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BFormTextarea', BFormTextarea);
// Form input 1
import { BFormCheckbox, BFormRadio, BFormRadioGroup, BFormSelect, BFormFile } from 'bootstrap-vue'
Vue.component('BFormCheckbox', BFormCheckbox);
Vue.component('BFormRadio', BFormRadio);
Vue.component('BFormRadioGroup', BFormRadioGroup);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormFile', BFormFile);
// Form input 2
import { BInputGroup, BInputGroupPrepend, BInputGroupAppend } from 'bootstrap-vue'
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupPrepend', BInputGroupPrepend);
Vue.component('BInputGroupAppend', BInputGroupAppend);

// Dropdown
import { BDropdown, BDropdownItem, BDropdownDivider } from 'bootstrap-vue'
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BDropdownDivider', BDropdownDivider);

// Navbar
import { BNavbar, BNavbarToggle, BNavItem, BNavItemDropdown } from 'bootstrap-vue'
Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarToggle', BNavbarToggle);
Vue.component('BNavItem', BNavItem);
Vue.component('BNavItemDropdown', BNavItemDropdown);

// Card
import { BCard, BCardHeader, BCardTitle, BCardSubTitle, BCardBody, BCardText } from 'bootstrap-vue'
Vue.component('BCard', BCard);
Vue.component('BCardHeader', BCardHeader);
Vue.component('BCardTitle', BCardTitle);
Vue.component('BCardSubTitle', BCardSubTitle);
Vue.component('BCardBody', BCardBody);
Vue.component('BCardText', BCardText);

// List Group
import { BListGroup, BListGroupItem } from 'bootstrap-vue'
Vue.component('BListGroup', BListGroup);
Vue.component('BListGroupItem', BListGroupItem);

// Media
import { BMedia, BMediaAside, BMediaBody,  } from 'bootstrap-vue'
Vue.component('BMedia', BMedia);
Vue.component('BMediaAside', BMediaAside);
Vue.component('BMediaBody', BMediaBody);
// #endregion
Vue.component(FeatherIcon.name, FeatherIcon)
Vue.component(DynamicIcon.name, DynamicIcon)
