/** Charts color palettes */
export const CHART_COLORS = {
  PLOTLY: ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'], // Plotly 10 default colors
  PLOTLY_HEX: [0x1f77b4, 0xff7f0e, 0x2ca02c, 0xd62728, 0x9467bd, 0x8c564b, 0xe377c2, 0x7f7f7f, 0xbcbd22, 0x17becf], // Plotly 10 default colors in Hex
};

/** Color Picker colors */
export const COLOR_PICKER = [
  // 14 Standard colors: http://changingminds.org/explanations/perception/visual/12-wheel.htm
  '#ff0000', // Red
  '#ff7f0a', // Orange
  '#ffff00', // Yellow
  '#7fff00', // Chartreuse Green
  '#00ff00', // Green
  '#00ff7f', // Spring Green
  '#00ffff', // Cyan
  '#007fff', // Azure
  '#0000ff', // Blue
  '#7f00ff', // Violet
  '#ff00ff', // Magenta
  '#ff007f', // Rose
  '#000000', // black
  '#ffffff', // White
  // Red
  '#FFEBEE',
  '#FFCDD2',
  '#EF9A9A',
  '#E57373',
  '#EF5350',
  '#F44336',
  '#E53935',
  '#D32F2F',
  '#C62828',
  '#B71C1C',
  '#FF8A80',
  '#FF5252',
  '#FF1744',
  '#D50000',
  // Pink
  '#FCE4EC',
  '#F8BBD0',
  '#F48FB1',
  '#F06292',
  '#EC407A',
  '#E91E63',
  '#D81B60',
  '#C2185B',
  '#AD1457',
  '#880E4F',
  '#FF80AB',
  '#FF4081',
  '#F50057',
  '#C51162',
  // Purple
  '#F3E5F5',
  '#E1BEE7',
  '#CE93D8',
  '#BA68C8',
  '#AB47BC',
  '#9C27B0',
  '#8E24AA',
  '#7B1FA2',
  '#6A1B9A',
  '#4A148C',
  '#EA80FC',
  '#E040FB',
  '#D500F9',
  '#AA00FF',
  // Deep Purple
  '#EDE7F6',
  '#D1C4E9',
  '#B39DDB',
  '#9575CD',
  '#7E57C2',
  '#673AB7',
  '#5E35B1',
  '#512DA8',
  '#4527A0',
  '#311B92',
  '#B388FF',
  '#7C4DFF',
  '#651FFF',
  '#6200EA',
  // Indigo
  '#E8EAF6',
  '#C5CAE9',
  '#9FA8DA',
  '#7986CB',
  '#5C6BC0',
  '#3F51B5',
  '#3949AB',
  '#303F9F',
  '#283593',
  '#1A237E',
  '#8C9EFF',
  '#536DFE',
  '#3D5AFE',
  '#304FFE',
  // Blue
  '#E3F2FD',
  '#BBDEFB',
  '#90CAF9',
  '#64B5F6',
  '#42A5F5',
  '#2196F3',
  '#1E88E5',
  '#1976D2',
  '#1565C0',
  '#0D47A1',
  '#82B1FF',
  '#448AFF',
  '#2979FF',
  '#2962FF',
  // Light Blue
  '#E1F5FE',
  '#B3E5FC',
  '#81D4FA',
  '#4FC3F7',
  '#29B6F6',
  '#03A9F4',
  '#039BE5',
  '#0288D1',
  '#0277BD',
  '#01579B',
  '#80D8FF',
  '#40C4FF',
  '#00B0FF',
  '#0091EA',
  // Cyan
  '#E0F7FA',
  '#B2EBF2',
  '#80DEEA',
  '#4DD0E1',
  '#26C6DA',
  '#00BCD4',
  '#00ACC1',
  '#0097A7',
  '#00838F',
  '#006064',
  '#84FFFF',
  '#18FFFF',
  '#00E5FF',
  '#00B8D4',
  // Teal
  '#E0F2F1',
  '#B2DFDB',
  '#80CBC4',
  '#4DB6AC',
  '#26A69A',
  '#009688',
  '#00897B',
  '#00796B',
  '#00695C',
  '#004D40',
  '#A7FFEB',
  '#64FFDA',
  '#1DE9B6',
  '#00BFA5',
  // Green
  '#E8F5E9',
  '#C8E6C9',
  '#A5D6A7',
  '#81C784',
  '#66BB6A',
  '#4CAF50',
  '#43A047',
  '#388E3C',
  '#2E7D32',
  '#1B5E20',
  '#B9F6CA',
  '#69F0AE',
  '#00E676',
  '#00C853',
  // Light Green
  '#F1F8E9',
  '#DCEDC8',
  '#C5E1A5',
  '#AED581',
  '#9CCC65',
  '#8BC34A',
  '#7CB342',
  '#689F38',
  '#558B2F',
  '#33691E',
  '#CCFF90',
  '#B2FF59',
  '#76FF03',
  '#64DD17',
  // Lime
  '#F9FBE7',
  '#F0F4C3',
  '#E6EE9C',
  '#DCE775',
  '#D4E157',
  '#CDDC39',
  '#C0CA33',
  '#AFB42B',
  '#9E9D24',
  '#827717',
  '#F4FF81',
  '#EEFF41',
  '#C6FF00',
  '#AEEA00',
  // Yellow
  '#FFFDE7',
  '#FFF9C4',
  '#FFF59D',
  '#FFF176',
  '#FFEE58',
  '#FFEB3B',
  '#FDD835',
  '#FBC02D',
  '#F9A825',
  '#F57F17',
  '#FFFF8D',
  '#FFFF00',
  '#FFEA00',
  '#FFD600',
  // Amber
  '#FFF8E1',
  '#FFECB3',
  '#FFE082',
  '#FFD54F',
  '#FFCA28',
  '#FFC107',
  '#FFB300',
  '#FFA000',
  '#FF8F00',
  '#FF6F00',
  '#FFE57F',
  '#FFD740',
  '#FFC400',
  '#FFAB00',
  // Orange
  '#FFF3E0',
  '#FFE0B2',
  '#FFCC80',
  '#FFB74D',
  '#FFA726',
  '#FF9800',
  '#FB8C00',
  '#F57C00',
  '#EF6C00',
  '#E65100',
  '#FFD180',
  '#FFAB40',
  '#FF9100',
  '#FF6D00',
  // Deep Orange
  '#FBE9E7',
  '#FFCCBC',
  '#FFAB91',
  '#FF8A65',
  '#FF7043',
  '#FF5722',
  '#F4511E',
  '#E64A19',
  '#D84315',
  '#BF360C',
  '#FF9E80',
  '#FF6E40',
  '#FF3D00',
  '#DD2C00',
  // Brown
  '#EFEBE9',
  '#D7CCC8',
  '#BCAAA4',
  '#A1887F',
  '#8D6E63',
  '#795548',
  '#6D4C41',
  '#5D4037',
  '#4E342E',
  '#3E2723',
  '#634741',
  '#553933',
  '#492f29',
  '#402823',
  // Gray
  '#FAFAFA',
  '#F5F5F5',
  '#EEEEEE',
  '#E0E0E0',
  '#BDBDBD',
  '#9E9E9E',
  '#757575',
  '#616161',
  '#424242',
  '#212121',
  '#5d5d5d',
  '#424141',
  '#2e2c2c',
  '#262424',
  // Blue Gray
  '#ECEFF1',
  '#CFD8DC',
  '#B0BEC5',
  '#90A4AE',
  '#78909C',
  '#607D8B',
  '#546E7A',
  '#455A64',
  '#37474F',
  '#263238',
  '#4d636e',
  '#435b67',
  '#35464f',
  '#2c3b42',
];

/** Palette Picker palettes  */
export const PALETTE_PICKER = [
  ['#011efe', '#07a566', '#3bff01', '#cdfe02', '#fd9801', '#fe0000'], // Rainbow
  ['#d9ed92', '#99d98c', '#52b69a', '#34a0a4', '#1a759f', '#184e77'], // Blue Gradient
  ['#53C8AC', '#AAD684', '#FBF87D', '#FCC66E', '#F298B1', '#9876B1'],
  ['#01BEFE', '#FFDD00', '#FF7D00', '#FF006D', '#ADFF02', '#8F00FF'],
  ['#4A291E', '#9C1027', '#C68C2A', '#DFBF87', '#4E8278', '#525250'], // Vintage
  ['#631C48', '#9F265C', '#CF2967', '#F15B67', '#FDB25F', '#FED883'], // Gradient In Retro
  ['#3E4A70', '#56658E', '#FF9978', '#FEB06E', '#98737B', '#6B637B'], // SUNRISE IN THE CITY

  ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b'], // d3.schemeCategory10
  ['#fdc086', '#ffff99', '#386cb0', '#f0027f', '#bf5b17', '#666666'], // d3.schemeAccent(2, 8)
  ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c'], // d3.schemePaired(0,6)
  ['#fdbf6f', '#ff7f00', '#cab2d6', '#6a3d9a', '#ffff99', '#b15928'], // d3.schemePaired(6,12)
  ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc'], // d3.schemePastel1(0, 6)
  ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33'], // d3.schemeSet1(0, 6)
  ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f'], // d3.schemeSet2(0, 6)
  ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462'], // d3.schemeSet3(0, 6)
  ['#4e79a7', '#f28e2c', '#e15759', '#76b7b2', '#59a14f', '#edc949'], // d3.schemeTableau10(0, 6)
  // Material Color: https://material.io/design/color/the-color-system.html#tools-for-picking-colors
  ['#ffebee', '#f1c2c4', '#e2989a', '#d46f70', '#c54546', '#b71c1c'],
  ['#fce4ec', '#e5b9cd', '#ce8ead', '#b6648e', '#9f396e', '#880e4f'],
  ['#f3e5f5', '#d1bbe0', '#af91cb', '#8e68b6', '#6c3ea1', '#4a148c'],
  ['#ede7f6', '#c7bee2', '#a295ce', '#7c6dba', '#5744a6', '#311b92'],
  ['#e8eaf6', '#bfc2de', '#969ac6', '#6c73ae', '#434b96', '#1a237e'],
  ['#e3f2fd', '#b8d0eb', '#8daed8', '#638bc6', '#3869b3', '#0d47a1'],
  ['#e1f5fe', '#b4d5ea', '#87b6d6', '#5b96c3', '#2e77af', '#01579b'],
  ['#e0f7fa', '#b3d9dc', '#86bbbe', '#5a9ca0', '#2d7e82', '#006064'],
  ['#e0f2f1', '#b3d1ce', '#86b0aa', '#5a8f87', '#2d6e63', '#004d40'],
  ['#e8f5e9', '#bfd7c1', '#96b999', '#6d9a70', '#447c48', '#1b5e20'],
  ['#f1f8e9', '#cbdbc0', '#a5bf98', '#7fa26f', '#598647', '#33691e'],
  ['#f9fbe7', '#e1e1bd', '#c9c694', '#b2ac6a', '#9a9141', '#827717'],
  ['#fffde7', '#fde4bd', '#fbcb94', '#f9b16a', '#f79841', '#f57f17'],
  ['#fff8e1', '#ffddb4', '#ffc187', '#ffa65a', '#ff8a2d', '#ff6f00'],
  ['#fff3e0', '#fad3b3', '#f5b286', '#f0925a', '#eb712d', '#e65100'],
  ['#fbe9e7', '#efc5bb', '#e3a18f', '#d77e64', '#cb5a38', '#bf360c'],
  ['#efebe9', '#ccc4c1', '#a89d9a', '#857572', '#614e4b', '#3e2723'],
  ['#fafafa', '#cfcfcf', '#a3a3a3', '#787878', '#4c4c4c', '#212121'],
  ['#eceff1', '#c4c9cc', '#9da3a7', '#757e82', '#4e585d', '#263238'],
];

// NetCDF color palettes
export const NETCDF_PALETTE = {
  BuGn: ['#edf8fb', '#ccece6', '#99d8c9', '#66c2a4', '#2ca25f', '#006d2c'],
  BuPu: ['#edf8fb', '#bfd3e6', '#9ebcda', '#8c96c6', '#8856a7', '#810f7c'],
  GnBu: ['#f0f9e8', '#ccebc5', '#a8ddb5', '#7bccc4', '#43a2ca', '#0868ac'],
  OrRd: ['#fef0d9', '#fdd49e', '#fdd49e', '#fc8d59', '#e34a33', '#b30000'],
  PuBu: ['#f1eef6', '#d0d1e6', '#a6bddb', '#74a9cf', '#2b8cbe', '#045a8d'],
  PuBuGn: ['#f6eff7', '#d0d1e6', '#a6bddb', '#67a9cf', '#1c9099', '#016c59'],
  PuRd: ['#f1eef6', '#d4b9da', '#c994c7', '#df65b0', '#dd1c77', '#980043'],
  YlGn: ['#ffffcc', '#d9f0a3', '#addd8e', '#78c679', '#31a354', '#006837'],
  YlOrRd: ['#ffffb2', '#fed976', '#feb24c', '#fd8d3c', '#f03b20', '#bd0026'],
  Greys: ['#f7f7f7', '#d9d9d9', '#bdbdbd', '#969696', '#636363', '#252525'],
  BrBG: ['#8c510a', '#d8b365', '#f6e8c3', '#c7eae5', '#5ab4ac', '#01665e'],
  PiYG: ['#c51b7d', '#e9a3c9', '#fde0ef', '#e6f5d0', '#a1d76a', '#4d9221'],
  PRGn: ['#762a83', '#af8dc3', '#e7d4e8', '#d9f0d3', '#7fbf7b', '#1b7837'],
  PuOr: ['#b35806', '#f1a340', '#fee0b6', '#d8daeb', '#998ec3', '#542788'],
  rainbow: ['#4E197F', '#2A63B3', '#4FB35D', '#D6D934', '#E19D23', '#CC3A38'],
  RdBu: ['#b2182b', '#ef8a62', '#fddbc7', '#d1e5f0', '#67a9cf', '#2166ac'],
  RdGy: ['#b2182b', '#ef8a62', '#fddbc7', '#e0e0e0', '#999999', '#4d4d4d'],
  RdYlBu: ['#d73027', '#fc8d59', '#fee090', '#e0f3f8', '#91bfdb', '#4575b4'],
  RdYlGn: ['#d73027', '#fc8d59', '#fee08b', '#d9ef8b', '#91cf60', '#1a9850'],
  Spectral: ['#d53e4f', '#fc8d59', '#fee08b', '#e6f598', '#99d594', '#3288bd'],
  Accent: ['#7fc97f', '#beaed4', '#fdc086', '#ffff99', '#ffff99', '#f0027f'],
  Dark2: ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02'],
  Paired: ['#a6cee3', '#1f78b4', '#b2df8a', '#33a02c', '#fb9a99', '#e31a1c'],
  Pastel1: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc'],
  Set1: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33'],
  Set2: ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f'],
  Set3: ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462'],
  viridis: ['#fde725', '#7ad151', '#22a884', '#2a788e', '#414487', '#440154'],
  inferno: ['#fcffa4', '#fca50a', '#dd513a', '#932667', '#420a68', '#000004'],
  magma: ['#fcfdbf', '#fe9f6d', '#de4968', '#8c2981', '#3b0f70', '#000004'],
  plasma: ['#f0f921', '#fca636', '#e16462', '#b12a90', '#6a00a8', '#6a00a8'],
};

// option chart plotly
export const OPTION_PLOTLY_PALETTE = {
  Plotly: ['#636EFA', '#EF553B', '#00CC96', '#AB63FA', '#FFA15A', '#19D3F3', '#FF6692', '#B6E880', '#FF97FF', '#FECB52'],
  D3: ['#1F77B4', '#FF7F0E', '#2CA02C', '#D62728', '#9467BD', '#8C564B', '#E377C2', '#7F7F7F', '#BCBD22', '#17BECF'],
  G10: ['#3366CC', '#DC3912', '#FF9900', '#109618', '#990099', '#0099C6', '#DD4477', '#66AA00', '#B82E2E', '#316395'],
  T10: ['#4C78A8', '#F58518', '#E45756', '#72B7B2', '#54A24B', '#EECA3B', '#B279A2', '#FF9DA6', '#9D755D', '#BAB0AC'],
  Alphabet: ['#AA0DFE', '#3283FE', '#85660D', '#782AB6', '#565656', '#1C8356', '#16FF32', '#F7E1A0', '#E2E2E2', '#1CBE4F', '#C4451C', '#DEA0FD', '#FE00FA', '#325A9B', '#FEAF16', '#F8A19F', '#90AD1C', '#F6222E', '#1CFFCE', '#2ED9FF', '#B10DA1', '#C075A6', '#FC1CBF', '#B00068', '#FBE426', '#FA0087'],
  Dark24: ['#2E91E5', '#E15F99', '#1CA71C', '#FB0D0D', '#DA16FF', '#222A2A', '#B68100', '#750D86', '#EB663B', '#511CFB', '#00A08B', '#FB00D1', '#FC0080', '#B2828D', '#6C7C32', '#778AAE', '#862A16', '#A777F1', '#620042', '#1616A7', '#DA60CA', '#6C4516', '#0D2A63', '#AF0038'],
  Light24: ['#FD3216', '#00FE35', '#6A76FC', '#FED4C4', '#FE00CE', '#0DF9FF', '#F6F926', '#FF9616', '#479B55', '#EEA6FB', '#DC587D', '#D626FF', '#6E899C', '#00B5F7', '#B68E00', '#C9FBE5', '#FF0092', '#22FFA7', '#E3EE9E', '#86CE00', '#BC7196', '#7E7DCD', '#FC6955', '#E48F72'],
  Set1: ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '  #999999'],
  Pastel1: ['#fbb4ae', '#b3cde3', '#ccebc5', '#decbe4', '#fed9a6', '#ffffcc', '#e5d8bd', '#fddaec', '#f2f2f2'],
  Dark2: ['#1b9e77', '#d95f02', '#7570b3', '#e7298a', '#66a61e', '#e6ab02', '#a6761d', '#666666'],
  Set2: ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f', '#e5c494', '#b3b3b3'],
  Pastel2: ['#b3e2cd', '#fdcdac', '#cbd5e8', '#f4cae4', '#e6f5c9', '#fff2ae', '#f1e2cc', '#cccccc'],
  Set3: ['#8dd3c7', '#ffffb3', '#bebada', '#fb8072', '#80b1d3', '#fdb462', '#b3de69', '#fccde5', '#d9d9d9', '#bc80bd', '#ccebc5', '#ffed6f'],
  Antique: ['#855c75', '#d9af6b', '#af6458', '#736f4c', '#526a83', '#625377', '#68855c', '#9c9c5e', '#a06177', '#8c785d', '#7c7c7c'],
  Bold: ['#7f3c8d', '#11a579', '#3969ac', '#f2b701', '#e73f74', '#80ba5a', '#e68310', '#008695', '#cf1c90', '#f97b72', '#a5aa99'],
  Pastel: ['#66c5cc', '#f6cf71', '#f89c74', '#dcb0f2', '#87c55f', '#9eb9f3', '#fe88b1', '#c9db74', '#8be0a4', '#b497e7', '#b3b3b3'],
  Prism: ['#5f4690', '#1d6996', '#38a6a5', '#0f8554', '#73af48', '#edad08', '#e17c05', '#cc503e', '#94346e', '#6f4070', '#666666'],
  Safe: ['#88ccee', '#cc6677', '#ddcc77', '#117733', '#332288', '#aa4499', '#44aa99', '#999933', '#882255', '#661100', '#888888'],
  Vivid: ['#e58606', '#5d69b1', '#52bca3', '#99c945', '#cc61b0', '#24796c', '#daa51b', '#2f8ac4', '#764e9f', '#ed645a', '#a5aa99'],
  seasonalByYear: ['#6d2976', '#6c3384', '#4e207c', '#6e5d9f', '#3f458d', '#325490', '#3a789f', '#1f829d', '#007f90', '#31a4a5', '#15a69a', '#00a684', '#67d2b0', '#00c06d', '#00ca5a', '#a0e897', '#84de0f', '#ade100', '#eaf276', '#ffe800'],
  seasonalByMonth: ['#e52325', '#6c6891', '#48988b', '#60a160', '#955d9e', '#da7343', '#ffb72f', '#f7f258', '#b97932', '#d4737f', '#de8cb7', '#a6a6a6'],
};
