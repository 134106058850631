const _ = require('lodash');
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    isActive: null,
    locations: [],
    locationsTemp: [],
    locationActive: null,
    files: {},
    filesVersion: 0,
  },
  mutations: {
    ADD_FILES_VERSION(state, payload) {
      state.filesVersion++;
    },
    RESET_FILES(state, payload) {
      state.files = {};
      state.filesVersion++;
    },
    ADD_FILES(state, payload) {
      if (!payload || !payload.id) return;
      state.files[payload.id] = payload;
    },
    RESET_DATA_MAP_BASED_TIMESERIES_LOADER(state, payload) {
      state.locations = [];
      state.locationsTemp = [];
      state.locationActive = null;
      state.files = {};
      state.filesVersion++;
    },
    RESET_MAP_BASED_TIMESERIES_LOADER(state, payload) {
      state.isActive = null;
      state.locations = [];
      state.locationsTemp = [];
      state.locationActive = null;
      state.files = {};
      state.filesVersion++;
    },

    UPDATE_ACTIVE(state, payload) {
      if (payload == null) {
        state.isActive = false;
      } else {
        state.isActive = payload;
      }
    },
    SET_LOCALTION(state, payload) {
      if (!payload) {
        state.locations = [];
      } else {
        state.locations = payload;
      }
    },
    ADD_LOCALTION(state, payload) {
      if (!payload) return;
      state.locations.push(payload);
    },
    UPDATE_LOCALTION(state, payload) {
      if (!payload.id) return;
      for (let i = 0; i < state.locations.length; i++) {
        if (state.locations[i].id == payload.id) {
          let newLocation = _.merge(state.locations[i], payload.data);
          state.locations[i] = newLocation;
          break;
        }
      }
    },
    UPDATE_LOCALTION_ACTIVE(state, payload) {
      state.locationActive = payload;
    },
    REMOVE_LOCALTION(state, payload) {
      if (!payload) return;
      try {
        state.locations = state.locations.filter((location) => location.id != payload);
      } catch {}
    },

    SET_LOCALTION_TEMP(state, payload) {
      if (!payload) {
        state.locationsTemp = [];
      } else {
        state.locationsTemp = payload;
      }
    },
    ADD_LOCALTION_TEMP(state, payload) {
      if (!payload) return;
      state.locationsTemp.push(payload);
    },
    UPDATE_LOCALTION_TEMP(state, payload) {
      if (!payload.id) return;
      for (let i = 0; i < state.locationsTemp.length; i++) {
        if (state.locationsTemp[i].id == payload.id) {
          let newLocation = _.merge(state.locationsTemp[i], payload.data);
          state.locationsTemp[i] = newLocation;
          break;
        }
      }
    },
    UPDATE_LOCALTION_ACTIVE_TEMP(state, payload) {
      state.locationActive = payload;
    },
    REMOVE_LOCALTION_TEMP(state, payload) {
      if (!payload) return;
      try {
        state.locationsTemp = state.locationsTemp.filter((location) => location.id != payload);
      } catch {}
    },
  },
};
