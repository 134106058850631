const _ = require('lodash')
import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    popupImages: [],
    processRate: [],
  },
  mutations: {
    ADD_POPUP_IMAGE(state, payload) {
      state.popupImages = state.popupImages.concat(payload)
    },
    UPDATE_IMAGE_METADATA(state, payload) {
      let index = state.popupImages.findIndex((i) => i.id === payload.imageId)
      state.popupImages[index].metadata = payload.metadata
    },
    DELETE_POPUP_IMAGE(state, payload) {
      let index = state.popupImages.findIndex((i) => i.id === payload.id)
      state.popupImages.splice(index, 1)
    },
    REPLACE_POPUP_IMAGE(state, payload) {
      let index = state.popupImages.findIndex((i) => i.id === payload.id)
      let oldLayer = state.popupImages[index]
      let newLayer = _.mergeWith({}, oldLayer, { fileName: payload.fileName })
      Vue.set(state.popupImages, index, newLayer)
      // state.popupImages[index].filename = payload.fileName;
    },
    DELETE_ALL_POPUP_IMAGES_BY_LAYER(state, payload) {
      let arrayImage = state.popupImages.filter((i) => i.layerId !== payload.layerId)
      state.popupImages = arrayImage
    },
    DELETE_ALL_POPUP_IMAGES(state) {
      state.popupImages = []
      state.processRate = []
    },

    // Process Rate Image
    ADD_PROCESS_RATE_IMAGE(state, payload) {
      try {
        if (payload) {
          state.processRate = state.processRate.concat(payload)
        }
      } catch {}
    },
    DELETE_PROCESS_RATE_IMAGE(state, payload) {
      try {
        let index = state.processRate.findIndex((i) => i.id === payload.id)
        if (index >= 0) {
          state.processRate.splice(index, 1)
        }
      } catch {}
    },
    REPLACE_PROCESS_RATE_IMAGE(state, payload) {
      try {
        let index = state.processRate.findIndex((i) => i.id === payload.id)
        if (index < 0) return
        let oldLayer = state.processRate[index]
        let newLayer = _.mergeWith({}, oldLayer, { fileName: payload.fileName })
        Vue.set(state.processRate, index, newLayer)
      } catch {}
    },
    UPDATE_STEP_PROCESS_RATE_IMAGE(state, payload) {
      try {
        let index = state.processRate.findIndex((i) => i.id === payload.id)
        if (index < 0) return
        let oldLayer = state.processRate[index]
        let newLayer = _.mergeWith({}, oldLayer, payload)
        Vue.set(state.processRate, index, newLayer)
      } catch {}
    },
  },
}
