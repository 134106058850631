const _ = require('lodash');
import Vue from 'vue';
import { STORAGE_MODE } from '@/constants/repository.js';
import { db, deleteTables } from '@/scripts/idbRepository';
import { formatBytes, checkNameOfTheFile,checkNameOfTheFileV2 } from '../utilities/DatasourceUtility.js';

export default {
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources;
    },
  },
  computed: {
    layers() {
      return this.$store.state.layer.layers;
    },
    mapClicking() {
      return this.$store.state.map.clicking;
    },
  },
  mounted() {
    // Init $db: cache columns, locations, items, dates
    if (!Vue.prototype.$db) Vue.prototype.$db = {};
  },
  methods: {
    /** Add new datasource metadata */
    async addDatasource(datasource) {
      let name = datasource.name;

      // Skip name check for DYNAMODB source
      if (datasource.storage_mode !== STORAGE_MODE.DYNAMODB) name = checkNameOfTheFileV2(this.datasources, datasource.name);
      // Store data meta to: $db (RAM)
      if (!Vue.prototype.$db[datasource.id]) Vue.prototype.$db[datasource.id] = {};
      let $dbObj = {
        columns: datasource.columns, // Object format of CSV columns NOT by order
        locationsItems: datasource.locationsItems, // Array format of CSV columns by order
        items: datasource.items, // Object format of CSV unique items
        locations: datasource.locations, // Object format of CSV unique locations
        dates: _.unionBy(datasource.dates), // Array format of CSV dates by order
        autoGroups: datasource.autoGroups,
        header: datasource.header,
        // NetCDF
        timeseries: datasource.timeseries, // Timeseries metadata of NetCDF
        listItems: datasource.listItems, // List items of NetCDF
        fileInfor: datasource.fileInfor,
      };
      Object.assign(Vue.prototype.$db[datasource.id], $dbObj);

      // Store file meta to: Vue Store
      let datasourceCommit = {
        id: datasource.id,
        name: name,
        nameOrigin:datasource.nameOrigin,
        type: datasource.type,
        storage_mode: datasource.storage_mode,
        path: datasource.path,
        size: formatBytes(datasource.size),
        sizeOrigin: datasource.size,
        file: datasource.file, // NetCDF
        fileOrigin: datasource.fileOrigin, 
        vectorMode: datasource.vectorMode, // NetCDF
        live: datasource.live, // AWS
        encoding: datasource.encoding,
      };
      if (datasource.storage_mode == STORAGE_MODE.S3) {
        datasourceCommit.bucket = datasource.bucket;
        datasourceCommit.keyBucket = datasource.keyBucket;
      }
      if (datasource.typeOrigin && datasource.typeOrigin == 'shp') {
        datasourceCommit.contentFileJson = datasource.data;
        datasourceCommit.typeOrigin = datasource.typeOrigin;
        datasourceCommit.items = datasource.items;
      }

      this.$store.commit('datasource/ADD_DATASOURCE', datasourceCommit);
    },
    /** Add new datasource and delete old datasource metadata & data */
    async updateDatasource(datasource) {
      await this.deleteDatasource(datasource);
      await this.addDatasource(datasource);
    },
    async updateDatasourceLiveMode(datasource) {
      // Store data meta to: $db (RAM)
      if (!Vue.prototype.$db[datasource.id]) Vue.prototype.$db[datasource.id] = {};
      let $dbObj = {
        columns: datasource.columns, // Object format of CSV columns NOT by order
        locationsItems: datasource.locationsItems, // Array format of CSV columns by order
        items: datasource.items, // Object format of CSV unique items
        locations: datasource.locations, // Object format of CSV unique locations
        dates: _.unionBy(datasource.dates), // Array format of CSV dates by order
        autoGroups: datasource.autoGroups,
        header: datasource.header,
        // NetCDF
        timeseries: datasource.timeseries, // Timeseries metadata of NetCDF
        listItems: datasource.listItems, // List items of NetCDF
        fileInfor: datasource.fileInfor,
      };
      Object.assign(Vue.prototype.$db[datasource.id], $dbObj);
    },
    /** Update list datasources metadata */
    async setDatasources(datasources) {
      this.$store.commit('datasource/SET_DATASOURCES', datasources);
    },
    /** Delete datasourcem metadata and data */
    async deleteDatasource(datasource) {
      try {
        if (this.layers && this.layers.length) {
          this.layers.map((layer) => {
            if (layer.dataImage && layer.dataImage == datasource.id) {
              layer.dataImage = null;
              layer.item = null;
              layer.version++;
            }
            if (layer.dataMulti && layer.dataMulti == datasource.id) {
              layer.dataMulti = null;
              layer.item = null;
              layer.version++;
            }
            if (layer.dataNetcdf && layer.dataNetcdf == datasource.id) {
              layer.dataNetcdf = null;
              layer.item = null;
              layer.version++;
            }
            if (layer.dataPoint && layer.dataPoint == datasource.id) {
              layer.dataPoint = null;
              layer.item = null;
              layer.version++;
            }
            if (layer.dataTimeseries && layer.dataTimeseries == datasource.id) {
              layer.dataTimeseries = null;
              layer.item = null;
              layer.version++;
            }
            if (layer.dataTyphoon && layer.dataTyphoon == datasource.id) {
              layer.dataTyphoon = null;
              layer.item = null;
              layer.version++;
            }
          });
        }
      } catch {}
      // Delete metadata
      this.$store.commit('datasource/DELETE_DATASOURCE', datasource);

      // Delete $db cache
      delete Vue.prototype.$db[datasource.id];

      // Delete indexedDB
      if (datasource.type === 'timeseries') {
        let names = db.tables.map((table) => table.name).filter((name) => name.startsWith(`${datasource.id}_`));
        await deleteTables(names);
      } else {
        await deleteTables([datasource.id]);
      }
    },
    /** Clear all datasources */
    async clearDatasource() {
      // Clear $db
      Vue.prototype.$db = {};

      try {
        // Clear indexedDB
        let names = db.tables.map((table) => table.name);
        if (names && names.length) {
          await deleteTables(names);
        }
      } catch {}

      // Clear metadata
      this.$store.commit('datasource/CLEAR_DATASOURCE');
    },
    getDatasourceEncoding(datasourceID) {
      const datasource = this.datasources.find((ds) => ds.id === datasourceID);
      return datasource ? datasource.encoding : 'UTF8';
    },
    checkStatisticData(datasourceID) {
      if (!this.$db[datasourceID]) return false;
      return ['Q1', 'Median', 'Q3', 'Lower', 'Upper', 'Outliers'].every((key) => Object.keys(this.$db[datasourceID].items).includes(key));
    },
  },
};
