import Vue from 'vue'
const _ = require('lodash')
const axios = require('axios')

export default {
  namespaced: true,
  state: {
    workerShowDialogSaveProject: null,
    workerSaveProjectContinue: null,
    workerSaveAsProjectContinue: null,
    workerNotSaveProjectContinue: null,
  },
  mutations: {
    WORKER_SHOW_DIALOG_SAVE_PROJECT(state, payload) {
      state.workerShowDialogSaveProject = payload
    },
    WORKER_SAVE_PROJECT_CONTINUE(state, payload) {
      state.workerSaveProjectContinue = payload
    },
    WORKER_SAVE_AS_PROJECT_CONTINUE(state, payload) {
      state.workerSaveAsProjectContinue = payload
    },
    WORKER_NOT_SAVE_PROJECT_CONTINUE(state, payload) {
      state.workerNotSaveProjectContinue = payload
    },
    RESET_WORKER_EVENT(state, payload) {
      state.workerSaveProjectContinue = null
      state.workerSaveAsProjectContinue = null
      state.workerNotSaveProjectContinue = null
    },
  },
}
