import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import datasource from './ecoplot-desktops/datasource.js'
import layer from './ecoplot-desktops/layer.js'
import map from './ecoplot-desktops/map.js'
import tabs from './ecoplot-desktops/tabs.js'
import ecoplot from './ecoplot-desktops/app.js'
import groupItems from './ecoplot-desktops/groupItems.js'
import settings from './ecoplot-desktops/settings.js'
import about from './ecoplot-desktops/about.js'
import image from './ecoplot-desktops/image.js'
import mapBasedTimeseriesLoader from './ecoplot-desktops/map-based-time-series-loader.js'
import workerWindowEvent from './worker-window-event/index.js'


Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    datasource,
    layer,
    map,
    tabs,
    ecoplot,
    groupItems,
    settings,
    about,
    image,
    mapBasedTimeseriesLoader,
    workerWindowEvent,
  },
  strict: process.env.DEV,
})
