// export const ECOPLOT_API = 'http://localhost:8000/api'
// export const ECOPLOT_PRO = 'https://localhost:3000'

export const ECOPLOT_API = 'https://ecoplotapi.besec.co.jp/api'
export const ECOPLOT_PRO = 'https://ecoplotpro.besec.co.jp'

// export const ECOPLOT_PRO_URL = process.env.ECOPLOT_PRO_URL || 'https://localhost:3000'
// export const ECOPLOT_API_URL = process.env.ECOPLOT_API_URL || 'http://localhost:8000'

export const CORRELATION_API = `${ECOPLOT_API}/correlation/`
export const DECOMPOSITION_API = `${ECOPLOT_API}/decompose/`


// // test in localhost:3000
// export const API_LAMBDA_DECOMPOSITON_CORRELATION = 'https://5chwkfy246inibjtlhxaq6sc2e0fntmm.lambda-url.ap-northeast-1.on.aws/'

// api in domain web:https://ecoplotpro.besec.co.jp/
export const API_LAMBDA_DECOMPOSITON_CORRELATION = 'https://3mktw67saibqawceskzsn4t2tm0xzhgg.lambda-url.ap-northeast-1.on.aws/'
