import Vue from 'vue'
const _ = require('lodash')
export default {
  namespaced: true,
  state: {
    datasources: [],
  },
  mutations: {
    ADD_DATASOURCE(state, payload) {
      const existedIndex = state.datasources.findIndex((d) => d.id == payload.id)
      if (existedIndex == -1) state.datasources.push(payload)
      else Vue.set(state.datasources, existedIndex, payload)
    },
    SET_DATASOURCES(state, payload) {
      state.datasources = payload
    },
    DELETE_DATASOURCE(state, payload) {
      state.datasources = state.datasources.filter((d) => d.id !== payload.id)
    },
    CLEAR_DATASOURCE(state) {
      state.datasources = []
    },
    UPDATE_DATASOURCE(state, datasource) {
      const existedIndex = state.datasources.findIndex((d) => d.id == datasource.id)
      if (existedIndex < 0) return
      Vue.set(state.datasources, existedIndex, datasource)
    },
    UPDATE_DATASOURCE_STATUS_LIVE(state, payload) {
      try {
        const existedIndex = state.datasources.findIndex((d) => d.id == payload.id)
        if (existedIndex < 0) return
        let datasource = _.merge(state.datasources[existedIndex], payload)
        Vue.set(state.datasources, existedIndex, datasource)
      } catch {}
    },
  },
}
