<template>
  <div v-show="showDialogQuit">
    <div class="dialog-quit-electron-screen"></div>
    <div class="dialog-quit-electron">
      <b-card class="dialog-quit-electron-card">
        <div class="dialog-quit-header d-flex align-items-center justify-content-between">
          <div>Ecoplot Pro</div>
          <div>
            <feather-icon class="dialog-quit-electron-x" @click.stop="showDialogQuit = false" icon="XIcon" size="16" />
          </div>
        </div>
        <div class="dialog-quit-content">
          <div><feather-icon class="dialog-quit-electron-warning" @click.stop="showDialogQuit = false" icon="AlertTriangleIcon" color="#ff7600" size="16" /> {{ $t('want_to_save_your_change') }}</div>
          <div class="dialog-quit-button">
            <b-button @click.stop="saveContinue" :variant="!isDark ? `outline-dark` : `outline-light`" size="sm" :class="isProjectShare && (!permisionRealtime || (permissionShare && permissionShare != 'write')) ? 'disabled-style' : ''">{{ $t('save') }}</b-button>
            <b-button @click.stop="saveAsContinue" :variant="!isDark ? `outline-dark` : `outline-light`" size="sm" :class="isProjectShare && !permisionRealtime ? 'disabled-style' : ''"  >{{ $t('save_as') }}</b-button>
            <b-button @click.stop="notSaveContinue" :variant="!isDark ? `outline-dark` : `outline-light`" size="sm">{{ $t('do_not_save') }}</b-button>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
// import { ipcRenderer } from 'electron';
import { ThemeConfig } from '@/mixins/ThemeMixin'
export default {
  mixins: [ThemeConfig],
  data() {
    return {
      showDialogQuit: false,
    }
  },
  mounted() {
    const self = this
    // ipcRenderer.on('on-worker-show-dialog-save-project', (e, correData) => {
    //   self.showDialogQuit = true;
    // });
  },
  computed: {
    onWorkerShowDialogSaveProject() {
      return this.$store.state.workerWindowEvent.workerShowDialogSaveProject
    },
    isProjectShare() {
      return this.$store.state.ecoplot.isProjectShare
    },
    permisionRealtime() {
      return this.$store.state.ecoplot.permisionRealtime
    },
    permissionShare() {
      return this.$store.state.ecoplot.permissionShare
    },
  },
  watch: {
    isDark() {},
    onWorkerShowDialogSaveProject: {
      deep: true,
      handler(onWorkerShowDialogSaveProject) {
        if (onWorkerShowDialogSaveProject) {
          this.showDialogQuit = true
        }
      },
    },
    showDialogQuit: {
      deep: true,
      handler(showDialogQuit) {
        if (showDialogQuit == false) {
          this.$store.commit('workerWindowEvent/WORKER_SHOW_DIALOG_SAVE_PROJECT', null)
        }
      },
    },
  },
  methods: {
    saveContinue() {
      // ipcRenderer.send('worker-save-project-continue');
      this.$store.commit('workerWindowEvent/WORKER_SAVE_PROJECT_CONTINUE', true)
      this.showDialogQuit = false
    },
    saveAsContinue() {
      this.$store.commit('workerWindowEvent/WORKER_SAVE_AS_PROJECT_CONTINUE', true)
      // ipcRenderer.send('worker-save-as-project-continue');
      this.showDialogQuit = false
    },
    notSaveContinue() {
      this.$store.commit('workerWindowEvent/WORKER_NOT_SAVE_PROJECT_CONTINUE', true)
      // ipcRenderer.send('worker-not-save-project-continue');
      this.showDialogQuit = false
    },
  },
}
</script>

<style lang="scss" scoped>
.dialog-quit-electron-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #b9b9b94d;
  z-index: 999999999;
}
.dialog-quit-electron {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999999999;
}
.dialog-quit-electron-card {
  .card-body {
    padding: 0;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .dialog-quit-header {
    font-size: 16px;
    padding: 0.5rem 1rem;
  }
  .dialog-quit-content {
    padding: 0.5rem 1rem 1rem 1rem;
    min-width: 330px;
    font-size: 15px;
  }
  .dialog-quit-button {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    margin-top: 1.5rem;
    button {
      margin-left: 0.5rem;
    }
  }
}
.select-save-method {
  margin-top: 1rem;
  select: {
    font-size: 14px;
    color: black;
  }
}
.dialog-quit-electron-x {
  margin-top: -5px;
  margin-right: -2px;
  cursor: pointer;
  &:hover {
    color: black;
  }
}
.dialog-quit-electron-warning {
  text-align: center;
  margin-right: 3px;
  margin-top: -4px;
}
.dark-layout {
  .dialog-quit-electron-x {
    &:hover {
      color: #fff;
    }
  }
}
.disabled-style {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
