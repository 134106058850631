const path = require('path');
import fs from 'fs';
import { v4 as uuidv4 } from 'uuid';
import { splitByLastIndex } from '@/utilities/StringUtility.js';

/** Check file name existed */
export function checkNameOfTheFile(datasources, newFileName) {
  // Ex 'export.csv'
  const counter = datasources.filter((f) => f.name === newFileName).length;
  // If counter >= 2, an error has already been passed to the files because it means
  // 2 files have the same name
  if (counter >= 2) {
    throw 'Error duplicate name already present';
  }
  if (counter === 0) {
    return newFileName;
  }
  if (counter === 1) {
    const newName = `${newFileName.split('.')[0]}_${counter}.${newFileName.split('.')[1]}`;
    // Return export_1.csv;
    return checkNameOfTheFile(datasources, newName);
    // We need to check if export_1.csv has not been already taken.
    // If so, the new name would be export_1_1.csv, not really pretty but it can be changed easily in this function
  }
}
function isNumber(value) {
  return !isNaN(Number(value)) && Number(value) !== Infinity && Number(value) !== -Infinity;
}
export function checkNameOfTheFileV2(datasources, newFileName) {
  let arrayName = [];
  try {
    for (let i = 0; i < datasources.length; i++) {
      arrayName.push(datasources[i].name);
    }
  } catch {}
  let nameCompare = newFileName;
  try {
    if (arrayName.length && arrayName.indexOf(nameCompare) >= 0) {
      let nameTemp = path.basename(newFileName, path.extname(newFileName));
      let extName = path.extname(newFileName);
      let idxLastChar = nameTemp.lastIndexOf('_');
      let nameOrigin = nameTemp;
      if (idxLastChar >= 0) {
        const countFile = nameTemp.slice(idxLastChar + 1);
        if (countFile != null && !isNumber(countFile)) {
          nameOrigin = nameTemp;
        } else {
          nameOrigin = nameTemp.slice(0, idxLastChar);
        }
      }
      let countWhile = 0;
      do {
        nameCompare = `${nameOrigin}${countWhile == 0 ? '' : `_${countWhile}`}${extName}`;
        countWhile++;
      } while (arrayName.indexOf(nameCompare) >= 0);
    }
  } catch {
    return checkNameOfTheFile(datasources, newFileName);
  }
  return nameCompare;
}

/** Check string contain Japanese character or not */
export function checkHasJapaneseCharacter(str) {
  return str.match(/[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf]/);
}

/** Format byte number */
export function formatBytes(bytes, decimals = 1) {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}
