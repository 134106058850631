const _ = require('lodash')
import Vue from 'vue'
import { DEFAULT_TABS } from '../../constants/app.js'

export default {
  namespaced: true,
  state: _.cloneDeep(DEFAULT_TABS),
  mutations: {
    SET_TIMESERIES(state, payload) {
      for (const key in payload) {
        Vue.set(state.timeseries, key, payload[key])
      }
    },
    SET_TIMESERIES_UPLOT(state, payload) {
      for (const key in payload) {
        Vue.set(state.timeseries_uplot, key, payload[key])
      }
    },
    SET_BAR(state, payload) {
      for (const key in payload) {
        Vue.set(state.bar, key, payload[key])
      }
    },
    SET_BAR_UPLOT(state, payload) {
      for (const key in payload) {
        Vue.set(state.bar_uplot, key, payload[key])
      }
    },
    SET_SCATTER(state, payload) {
      for (const key in payload) {
        Vue.set(state.scatter, key, payload[key])
      }
    },
    SET_SCATTER_UPLOT(state, payload) {
      for (const key in payload) {
        Vue.set(state.scatter_uplot, key, payload[key])
      }
    },
    // SET_SCATTER_BUBBLE(state, payload) {
    //   for (const key in payload) {
    //     Vue.set(state.scatter_bubble, key, payload[key])
    //   }
    // },
    SET_SCATTER_D3(state, payload) {
      for (const key in payload) {
        Vue.set(state.scatter_d3, key, payload[key])
      }
    },
    SET_SCATTER_THREE(state, payload) {
      for (const key in payload) {
        Vue.set(state.scatter_three, key, payload[key])
      }
    },
    SET_BOXPLOT(state, payload) {
      for (const key in payload) {
        Vue.set(state.boxplot, key, payload[key])
      }
    },
    SET_BOXPLOT_UPLOT(state, payload) {
      for (const key in payload) {
        Vue.set(state.boxplot_uplot, key, payload[key])
      }
    },
    SET_HISTOGRAM(state, payload) {
      for (const key in payload) {
        Vue.set(state.histogram, key, payload[key])
      }
    },
    SET_HISTOGRAM_UPLOT(state, payload) {
      for (const key in payload) {
        Vue.set(state.histogram_uplot, key, payload[key])
      }
    },
    SET_DECOMPOSITION(state, payload) {
      for (const key in payload) {
        Vue.set(state.decomposition, key, payload[key])
      }
    },
    SET_SEASONAL_ANALYTICS(state, payload) {
      for (const key in payload) {
        Vue.set(state.seasonal_analytics, key, payload[key])
      }
    },
    SET_CORRELATION(state, payload) {
      for (const key in payload) {
        Vue.set(state.correlation, key, payload[key])
      }
    },
    SET_MACHINE_LEARNING(state, payload) {
      for (const key in payload) {
        Vue.set(state.machine_learning, key, payload[key])
      }
    },

    SET_LINES(state, payload) {
      for (const key in payload) {
        Vue.set(state.lines, key, payload[key])
      }
    },
    SET_LINES_UPLOT(state, payload) {
      for (const key in payload) {
        Vue.set(state.lines_uplot, key, payload[key])
      }
    },
    SET_DATASOURCE_TOOLS_OUILIER(state, payload) {
      for (const key in payload) {
        Vue.set(state.datasouce_tools.outlier, key, payload[key])
      }
    },
    //NETCDF Timeseries
    SET_NETCDF_TIMESERIES_METADATA(state, payload) {
      try {
        if (payload.routeName === 'timeseries') {
          if (payload.init) {
            for (let i = 0; i < state.timeseries.arrayDatasourceSelect.length; i++) {
              const timeseriesLayoutSelected = state.timeseries.arrayDatasourceSelect[i]
              if (!timeseriesLayoutSelected.netcdfMetadata[payload.id]) timeseriesLayoutSelected.netcdfMetadata[payload.id] = {}
              timeseriesLayoutSelected.netcdfMetadata[payload.id][payload.item] = payload.data
            }
          } else {
            let timeseriesLayoutSelected = state.timeseries.arrayDatasourceSelect.find((ds) => ds.id === state.timeseries.selectTimeseries)
            if (!timeseriesLayoutSelected) return
            if (!timeseriesLayoutSelected.netcdfMetadata[payload.id]) timeseriesLayoutSelected.netcdfMetadata[payload.id] = {}
            timeseriesLayoutSelected.netcdfMetadata[payload.id][payload.item] = payload.data
          }
          return
        }
        if (payload.routeName === 'boxplot' || payload.routeName === 'histogram' || payload.routeName === 'decomposition' || payload.routeName === 'seasonal_analytics' || payload.routeName === 'correlation') {
          if (!state[payload.routeName].netcdfMetadata[payload.id]) state[payload.routeName].netcdfMetadata[payload.id] = {}
          state[payload.routeName].netcdfMetadata[payload.id][payload.item] = payload.data
          if (payload.routeName === 'decomposition' || payload.routeName === 'seasonal_analytics') {
            if (payload.data.gridSelected.length > 0) {
              let keyDatasourceSelected = []
              state[payload.routeName].datasourceSelected.map((data) => {
                if (!data.localtionItem) {
                  keyDatasourceSelected.push(`id${data.datasource}$item${data.item}`)
                }
              })
              let index = keyDatasourceSelected.indexOf(`id${payload.id}$item${payload.item}`)
              if (index > -1) {
                state[payload.routeName].datasourceSelected[index].datasource = `${payload.id}`
                state[payload.routeName].datasourceSelected[index].item = `${payload.item}`
              } else {
                state[payload.routeName].datasourceSelected.push({
                  datasource: `${payload.id}`,
                  item: `${payload.item}`,
                  location: null,
                })
              }
              state[payload.routeName].datasourceSelected = state[payload.routeName].datasourceSelected.filter((ds) => !ds.location)
            }
          }
          return
        }
      } catch {}
    },
    //SET CELL IN ALL DATASOURCE NETCDF Timeseries
    SET_ALL_NETCDF_TIMESERIES_METADATA(state, dataCommit) {
      try {
        let routeName = dataCommit.routeName
        let payload = dataCommit.netcdfItemSelectedTemp
        if (routeName === 'timeseries') {
          let selectTimeseries = _.cloneDeep(state.timeseries.selectTimeseries)
          let indexSelectTimeseries = 0
          if (selectTimeseries === 'xy1') indexSelectTimeseries = 0
          if (selectTimeseries === 'xy2') indexSelectTimeseries = 1
          if (selectTimeseries === 'xy3') indexSelectTimeseries = 2
          if (selectTimeseries === 'xy4') indexSelectTimeseries = 3
          let timeseriesLayoutSelected = _.cloneDeep(state.timeseries.arrayDatasourceSelect[indexSelectTimeseries])
          let keyDatasourceSelected = []
          timeseriesLayoutSelected.datasourceSelected.map((data) => {
            keyDatasourceSelected.push(`id${data.datasource}$item${data.item}`)
          })
          if (Object.keys(timeseriesLayoutSelected.netcdfMetadata).length > 0) {
            for (let idDatasource in timeseriesLayoutSelected.netcdfMetadata) {
              if (timeseriesLayoutSelected.netcdfMetadata[idDatasource][payload.item]) {
                timeseriesLayoutSelected.netcdfMetadata[idDatasource][payload.item].gridOption = payload.gridOption
                timeseriesLayoutSelected.netcdfMetadata[idDatasource][payload.item].gridSelected = payload.gridSelected
                timeseriesLayoutSelected.netcdfMetadata[idDatasource][payload.item].drawBackground = payload.drawBackground
                timeseriesLayoutSelected.datasourceSelected = _.unionBy(timeseriesLayoutSelected.datasourceSelected, [{ datasource: idDatasource, item: payload.item, location: payload.location }], 'item', 'datasource')
              }
            }
          }
          state.timeseries.arrayDatasourceSelect[indexSelectTimeseries] = { ...timeseriesLayoutSelected }
          state.timeseries.datasourceSelected = timeseriesLayoutSelected.datasourceSelected
          return
        }
        if (routeName === 'boxplot' || routeName === 'histogram' || routeName === 'decomposition' || routeName === 'seasonal_analytics' || routeName === 'correlation') {
          let tabData = _.cloneDeep(state[routeName])
          let keyDatasourceSelected = []
          tabData.datasourceSelected.map((data) => {
            keyDatasourceSelected.push(`id${data.datasource}$item${data.item}`)
          })
          if (Object.keys(tabData.netcdfMetadata).length > 0) {
            for (let idDatasource in tabData.netcdfMetadata) {
              if (tabData.netcdfMetadata[idDatasource][payload.item]) {
                tabData.netcdfMetadata[idDatasource][payload.item].gridOption = payload.gridOption
                tabData.netcdfMetadata[idDatasource][payload.item].gridSelected = payload.gridSelected
                tabData.netcdfMetadata[idDatasource][payload.item].drawBackground = payload.drawBackground
                tabData.datasourceSelected = _.unionBy(tabData.datasourceSelected, [{ datasource: idDatasource, item: payload.item, location: payload.location }], 'item', 'datasource')
              }
            }
          }
          if (routeName === 'decomposition') {
            if (payload.gridSelected.length > 0) {
              tabData.datasourceSelected = tabData.datasourceSelected.filter((ds) => !ds.location)
            } else {
              tabData.datasourceSelected = tabData.datasourceSelected.filter((ds) => ds.location)
            }
          }
          state[routeName] = { ...tabData }
          return
        }
      } catch {}
    },
    CLEAR_ALL_NETCDF_TIMESERIES_METADATA(state, payload) {
      try {
        if (payload.routeName === 'timeseries') {
          state.timeseries.netcdfItemSelectedTemp = {}
          state.timeseries.datasourceSelected = state.timeseries.datasourceSelected.filter((data) => data.location)
          state.timeseries.arrayDatasourceSelect.map((data) => {
            for (let idObject in data.netcdfMetadata) {
              for (let localtionItem in data.netcdfMetadata[idObject]) {
                data.netcdfMetadata[idObject][localtionItem].drawBackground = true
                data.netcdfMetadata[idObject][localtionItem].gridOption = 50
                data.netcdfMetadata[idObject][localtionItem].gridSelected = []
              }
            }
          })
          return
        }
        if (payload.routeName === 'boxplot' || payload.routeName === 'histogram' || payload.routeName === 'decomposition' || payload.routeName === 'seasonal_analytics' || payload.routeName === 'correlation') {
          state[payload.routeName].netcdfItemSelectedTemp = {}
          state[payload.routeName].datasourceSelected = state[payload.routeName].datasourceSelected.filter((data) => data.location)
          for (let idObject in state[payload.routeName].netcdfMetadata) {
            for (let localtionItem in state[payload.routeName].netcdfMetadata[idObject]) {
              state[payload.routeName].netcdfMetadata[idObject][localtionItem].drawBackground = true
              state[payload.routeName].netcdfMetadata[idObject][localtionItem].gridOption = 50
              state[payload.routeName].netcdfMetadata[idObject][localtionItem].gridSelected = []
            }
          }
          return
        }
      } catch {
        return
      }
    },
    SET_DATASOURCE_TIMESERIES_DECOMPOSITION(state, payload) {
      try {
        let netcdfMetadata = state.decomposition.netcdfMetadata
        if (!netcdfMetadata || Object.keys(netcdfMetadata).length <= 0) return
        for (let idObject in netcdfMetadata) {
          for (let localtionItem in netcdfMetadata[idObject]) {
            state.decomposition.netcdfMetadata[idObject][localtionItem].drawBackground = true
            state.decomposition.netcdfMetadata[idObject][localtionItem].gridOption = 50
            state.decomposition.netcdfMetadata[idObject][localtionItem].gridSelected = []
          }
        }
      } catch {
        return
      }
    },
    SET_DATASOURCE_TIMESERIES_SEASONAL_ANALYTICS(state, payload) {
      try {
        let netcdfMetadata = state.seasonal_analytics.netcdfMetadata
        if (!netcdfMetadata || Object.keys(netcdfMetadata).length <= 0) return
        for (let idObject in netcdfMetadata) {
          for (let localtionItem in netcdfMetadata[idObject]) {
            state.seasonal_analytics.netcdfMetadata[idObject][localtionItem].drawBackground = true
            state.seasonal_analytics.netcdfMetadata[idObject][localtionItem].gridOption = 50
            state.seasonal_analytics.netcdfMetadata[idObject][localtionItem].gridSelected = []
          }
        }
      } catch {
        return
      }
    },

    SET_NETCDF_TIMESERIES_DATA_OUTPUT(state, payload) {
      if (payload.routeName === 'timeseries') {
        let timeseriesLayoutSelected = state.timeseries.arrayDatasourceSelect.find((ds) => ds.id === payload.id)
        if (!timeseriesLayoutSelected) return
        timeseriesLayoutSelected.netcdfDataChart = payload.timeseriesData
        return
      }
      if (payload.routeName === 'boxplot' || payload.routeName === 'histogram' || payload.routeName === 'decomposition' || payload.routeName === 'seasonal_analytics' || payload.routeName === 'correlation') {
        state[payload.routeName].netcdfDataChart = payload.timeseriesData
        return
      }
    },

    DELETE_NETCDF_METADATA(state, id) {
      // tab timeseries
      for (let i = 0; i < state.timeseries.arrayDatasourceSelect.length; i++) {
        delete state.timeseries.arrayDatasourceSelect[i].netcdfMetadata[id]
      }
      // tab boxplot
      delete state.boxplot.netcdfMetadata[id]
      delete state.histogram.netcdfMetadata[id]
      delete state.decomposition.netcdfMetadata[id]
      delete state.seasonal_analytics.netcdfMetadata[id]
      delete state.correlation.netcdfMetadata[id]
    },

    NETCDF_REDRAW_DATA(state, payload) {
      if (payload && payload.routeName === 'timeseries') {
        state.timeseries.redrawNetcdfData++
        return
      }
      if (payload && (payload.routeName === 'boxplot' || payload.routeName === 'histogram' || payload.routeName === 'decomposition' || payload.routeName === 'correlation')) {
        state[payload.routeName].redrawNetcdfData++
        return
      }
    },
    NETCDF_FISRT_SELECTED(state, payload) {
      state.timeseries.firstSelectedNetcdf = payload
    },

    SET_NETCDF_TIMESERIES_STATUS(state, payload) {
      if (payload && payload.routeName) {
        Vue.set(state[payload.routeName], 'netcdfStatus', payload.data)
      }
    },

    RESTORE_TABS(state, { timeseries, lines, scatter, scatter_d3, scatter_three, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning }) {
      state.timeseries = timeseries
      state.scatter = scatter
      state.scatter_d3 = scatter_d3
      state.scatter_three = scatter_three
      state.boxplot = boxplot
      state.histogram = histogram
      state.decomposition = decomposition
      state.seasonal_analytics = seasonal_analytics
      state.correlation = correlation
      state.machine_learning = machine_learning
      state.lines = lines
    },
    RESTORE_TABS_DATE(state, { timeseries, lines, scatter, scatter_d3, scatter_three, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning }) {
      Vue.set(state.timeseries, 'date', timeseries.date)
      Vue.set(state.scatter, 'date', scatter.date)
      Vue.set(state.scatter_d3, 'date', scatter_d3.date)
      Vue.set(state.scatter_three, 'date', scatter_three.date)
      Vue.set(state.boxplot, 'date', boxplot.date)
      Vue.set(state.histogram, 'date', histogram.date)
      Vue.set(state.decomposition, 'date', decomposition.date)
      Vue.set(state.seasonal_analytics, 'date', seasonal_analytics.date)
      Vue.set(state.correlation, 'date', correlation.date)
      Vue.set(state.lines, 'date', lines.date)
    },
    RESET_STATE(state) {
      let { timeseries, bar, scatter, scatter_d3, scatter_three, boxplot, histogram, decomposition, seasonal_analytics, correlation, machine_learning, lines } = _.cloneDeep(DEFAULT_TABS)
      state.timeseries = timeseries
      state.scatter = scatter
      state.scatter_d3 = scatter_d3
      state.scatter_three = scatter_three
      state.boxplot = boxplot
      state.histogram = histogram
      state.decomposition = decomposition
      state.seasonal_analytics = seasonal_analytics
      state.correlation = correlation
      state.machine_learning = machine_learning
      state.lines = lines
      state.bar = bar
    },
    UPDATE_AUTOGROUP(state, payload) {
      if (payload && (payload.routeName === 'timeseries' || payload.routeName === 'boxplot' || payload.routeName === 'histogram' || payload.routeName === 'correlation')) {
        state[payload.routeName].autoGroup = payload.autoGroup
        return
      }
    },
    UPDATE_RESAMPLE_TIMESERIES(state, payload) {
      let resample = _.cloneDeep(state.timeseries.arrayDatasourceSelect[payload.indexDatasourceSeleted].resample)
      state.timeseries.arrayDatasourceSelect[payload.indexDatasourceSeleted].resample = _.merge(resample, payload.resample)
    },
  },
}
