import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import AsyncComputed from 'vue-async-computed'
import DatasourceMixin from '@/mixins/DatasourceMixin'
import RepositoryMixin from '@/mixins/RepositoryMixin'
import { logger } from '@/mixins/LoggerMixin'

import i18n from './libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import './libs/axios.js'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Bootstrap Vue Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(AsyncComputed)

// Composition API
Vue.use(VueCompositionAPI)

// Global Mixin Registration
Vue.mixin(DatasourceMixin)
Vue.mixin(RepositoryMixin)

// Global mixin vue-logger (loggerMixin)
Vue.mixin(logger)

// Feather font icon
// * Shall remove it if not using font-icons of feather-icons
require('./@core/assets/fonts/feather/iconfont.css')

// import core styles
require('./@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

import('leaflet/dist/leaflet.css')
import('leaflet-distortableimage/dist/vendor.css')
import('leaflet-distortableimage/dist/leaflet.distortableimage.css')

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import 'nouislider/dist/nouislider.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
