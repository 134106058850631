import { STORAGE_MODE } from '@/constants/repository.js'
import * as ram from '@/scripts/ramRepository'
import * as dynamoDB from '@/scripts/dynamodbRepository'
import * as s3 from '@/scripts/s3Repository';

export default {
  computed: {
    datasources() {
      return this.$store.state.datasource.datasources
    },
  },
  methods: {
    /** Decide which repository using for datasource */
    decideRepository(datasourceId) {
      const datasource = this.datasources.find((d) => d.id === datasourceId)
      if (!datasource) {
        throw 'Datasource "' + datasource + '" not found.'
      }

      if (datasource.storage_mode === STORAGE_MODE.RAM) {
        return ram
      } else if (datasource.storage_mode === STORAGE_MODE.DYNAMODB) {
        return dynamoDB
      }
      else if (datasource.storage_mode === STORAGE_MODE.S3) {
      return s3;
      }
      throw 'Invalid storage mode: ' + datasource.storage_mode
    },

    /** Select all data in $db
     * @param {String} datasourceId String 'Id' of datasource
     * @param {Scale} scale Scale data when return: 'original' | 'log10' | 'normalize' | 'standardize'
     * @returns Array of records */
    selectAll(datasourceId, scale) {
      scale = scale || 'original'
      const encoding = this.getDatasourceEncoding(datasourceId)
      const repository = this.decideRepository(datasourceId)
      return repository.selectAll(datasourceId, scale, encoding)
    },

    /** Select by a location-item pair by ALL time-range
     * @param {String} datasourceId String 'Id' of datasource
     * @param {Array} locationsItems Array location and item pair. Ex: ['01-ph', '02-ph',...]
     * @param {Scale} scale Scale data when return: 'original' | 'log10' | 'normalize' | 'standardize'
     * @returns Array of records */
    async selectAllByLocationsItems(datasourceId, locationsItems, scale) {
      scale = scale || 'original'
      this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', true)
      const repository = this.decideRepository(datasourceId)
      const encoding = this.getDatasourceEncoding(datasourceId)
      const result = await repository.selectAllByLocationsItems(datasourceId, locationsItems, scale, encoding)
      this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', false)
      return result
    },

    /** Select by location-item pair in a time-range
     * @param {String} datasourceId String 'Id' of datasource
     * @param {Array} locationsItems Array location and item pair. Ex: ['01-ph', '02-ph',...]
     * @param {Date} minDate Start date to query from
     * @param {Date} maxDate End date to query to
     * @param {Scale} scale Scale data when return: 'original' | 'log10' | 'normalize' | 'standardize'
     * @returns Array of records */
    async selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, scale) {
      scale = scale || 'original'
      this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', true)
      const repository = this.decideRepository(datasourceId)
      const encoding = this.getDatasourceEncoding(datasourceId)
      const result = await repository.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, scale, encoding)
      this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', false)
      return result
    },

    async checkLocations(locationsItems, dX, scale) {
      const repository = this.decideRepository(dX)
      const encoding = this.getDatasourceEncoding(dX)
      const result = await repository.checkLocations(locationsItems, dX, scale, encoding)
      return result
    },

    /** Select all locations by item with date only */
    async selectDateByItem(datasourceId, item, date, scale) {
      scale = scale || 'original'
      this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', true)
      const repository = this.decideRepository(datasourceId)
      const encoding = this.getDatasourceEncoding(datasourceId)
      const result = await repository.selectDateByItem(datasourceId, item, date, scale, encoding)
      this.$store.commit('ecoplot/SET_STATUS_FILE_EXECUTE', false)
      return result
    },

    /** Get all Points of a table */
    getAllPoints(table) {
      return this.decideRepository(table).getAllPoints(table)
    },

    /** Get all typhoon data */
    getAllTyphoons(table) {
      return this.decideRepository(table).getAllTyphoons(table)
    },

    /** Get all GeoJson data */
    getGeojson(table) {
      return this.decideRepository(table).getGeojson(table)
    },

    /** Get all image data */
    getImage(table) {
      return this.decideRepository(table).getImage(table)
    },

    /** Get header, dates from timeseries csv file */
    /**
     * @param {String} filePath: Path to csv file
     * @param {String} encoding
     * @returns Object data includes:  all dates of csv File, header, min-max value of each item */
    selectDatesAndHeaderTimeseriesByPath(filePath, encoding, hasItems) {
      return file.selectDatesAndHeaderTimeseriesByPath(filePath, encoding, hasItems)
    },
    /** Get datatable for FILE mode */
    selectDataTable(datasourceId, sliceFrom, sliceTo) {
      const encoding = this.getDatasourceEncoding(datasourceId)
      return file.selectDataTable(datasourceId, encoding, sliceFrom, sliceTo)
    },
  },
}
