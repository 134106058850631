const _ = require('lodash')
import * as ram from '@/scripts/ramRepository'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export async function selectAll(datasourceId, scale) {
  return ram.selectAll(datasourceId, scale);
}

export async function selectAllByLocationsItems(datasourceId, locationsItems, scale) {
  return ram.selectAllByLocationsItems(datasourceId, locationsItems, scale);
}

export async function selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, scale) {
  return ram.selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, scale);
}

export async function selectDateByItem(datasourceId, item, date) {
  return ram.selectDateByItem(datasourceId, item, date);
}

export async function getAllPoints(table) {
  return ram.getAllPoints(table);
}

export async function getAllTyphoons(table) {
  return ram.getAllTyphoons(table);
}

export async function getGeojson(table) {
  return ram.getGeojson(table);
}

export async function getImage(table) {
  return ram.getImage(table);
}



// export async function downloadFile(key) {
//   const fileName = splitByLastIndex(key, '/')[1];
//   const bucketParams = { Bucket: "ecoplot", Key: key };

//   // Get object metadata
//   // const fileMeta = await s3Client.send(new HeadObjectCommand(bucketParams));

//   // Get object from the Amazon S3 bucket. It is returned as a ReadableStream.
//   const data = await s3Client.send(new GetObjectCommand(bucketParams));

//   // Convert the ReadableStream to a string.
//   const fileContent = await streamToString(data.Body);
//   const file = new File([fileContent], fileName);
//   return file;
// }

// export async function addDataDemo(datasourceId) {
//   const datasource = Vue.prototype.$db[datasourceId];
//   const dates = datasource.dates;
//   const items = Object.keys(datasource.items);
//   const locations = Object.keys(datasource.locations);

//   // 1. Init default Dates live $db
//   if (!Vue.prototype.$db[datasourceId]['datesLive'])
//     Vue.prototype.$db[datasourceId]['datesLive'] = [];
  
//   // 2. Get $db_live last date or $db_origin last date
//   const datesLive = Vue.prototype.$db[datasourceId]['datesLive'];
//   let lastDate = datesLive[datesLive.length - 1];
//   if (!lastDate) {
//     lastDate = dates[dates.length -1];
//   }
//   const newDate = dayjs(lastDate).add(3, 'hour').format(DATE_FORMAT);
//   Vue.prototype.$db[datasourceId]['datesLive'].push(newDate);

//   // 3. Loop items create data_ITEM_live $db table
//   for (const item of items) {
//     // 4. Init default Item live $db
//     if (!Vue.prototype.$db[datasourceId][`data_${item}_live`])
//       Vue.prototype.$db[datasourceId][`data_${item}_live`] = {};

//     // 5. Loop locations create location-item rows
//     const { min, max } = datasource.items[item];
//     for (const location of locations) {
//       const value = random(min, max);
//       const locationItem = location + '-' + item;
//       if (!Vue.prototype.$db[datasourceId][`data_${item}_live`][locationItem])
//         Vue.prototype.$db[datasourceId][`data_${item}_live`][locationItem] = [];
//       Vue.prototype.$db[datasourceId][`data_${item}_live`][locationItem].push(value);
//     }
//   }
// }