<template>
  <div id="app" class="h-100" :class="[skinClasses]">
    <keep-alive>
      <component :is="layout">
        <router-view />
      </component>
    </keep-alive>
    <!-- <DialogQuit></DialogQuit> -->
    <DialogSaveProject></DialogSaveProject>
  </div>
</template>

<script>
const _ = require('lodash')
// import ScrollToTop from './@core/components/scroll-to-top/ScrollToTop.vue'
// const customTitlebar = require('custom-electron-titlebar')
import Vue from 'vue'
import { BOverlay } from 'bootstrap-vue'
import { $themeColors, $themeBreakpoints, $themeConfig } from '../themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from './@core/app-config/useAppConfig'
import { useWindowSize, useCssVar } from '@vueuse/core'
import store from '@/store'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
// import DialogQuit from '@/views/ecoplot-desktops/extra-windows/DialogQuit.vue'
import DialogSaveProject from '@/views/ecoplot-desktops/extra-windows/DialogSaveProject.vue'
const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  name: 'App',
  components: { DialogSaveProject, LayoutHorizontal, LayoutVertical, LayoutFull, BOverlay, ToastificationContent },
  data() {
    return {
      MyTitleBar: null,
    }
  },
  async beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.handleBeforeUnload)
  },
  created() {},
  async mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload)
    // get user
    this.$store.dispatch('ecoplot/GET_USER')
    Vue.prototype.$latlngOnMap = {}
    Vue.prototype.$imagesSrc = {}
    Vue.prototype.$imagesExtract = {}
    Vue.prototype.$layerStatus = {}
    Vue.prototype.$mapBasedTimeseriesLoader = {}
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, (val) => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    recordingStatus() {
      return this.$store.state.ecoplot.recordingStatus || 'stop'
    },
    isGifing() {
      return this.$store.state.ecoplot.isGifing
    },
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    user() {
      return this.$store.state.ecoplot.user
    },
    error() {
      return this.$store.state.ecoplot.error
    },
  },
  watch: {
    $route(to, from) {
      if (to.name == 'embed') return
      let token = localStorage.getItem('django-authentication-token')
      if (token) {
        if (to.name == 'login') {
          this.$router.push({ path: '/map' }).catch(() => {})
        }
      } else {
        if (!to || !to.name || !(to.name == 'forget-password' || to.name == 'reset-password')) {
          this.$router.push({ path: '/login' }).catch(() => {})
        }
      }
    },
    user: {
      deep: true,
      handler() {
        if (this.$route.name == 'embed' || !this.$route.name) return
        if (this.user === null) {
          this.$router.push({ path: '/login' }).catch(() => {})
        } else {
          if (this.$fromRoute && this.$fromRoute.query && this.$fromRoute.query.shareId) {
            this.$router.push({ path: this.$fromRoute.fullPath }).catch(() => {})
          } else {
            if (this.$route.name == 'login') {
              this.$router.push({ path: '/map' }).catch(() => {})
            }
          }
        }
      },
    },
    // recordingStatus() {
    //   if (this.recordingStatus === 'recording') {
    //     this.MyTitleBar.updateTitle(`${APP_NAME} 🔴`)
    //   } else {
    //     this.MyTitleBar.updateTitle(APP_NAME)
    //   }
    // },
    // isGifing(isGifing) {
    //   if (isGifing) {
    //     this.MyTitleBar.updateTitle(`${APP_NAME} 🔴 Exporting GIF file... Press ESC to cancel`)
    //   } else {
    //     this.MyTitleBar.updateTitle(APP_NAME)
    //   }
    // },
  },
  methods: {
    async delay(ms) {
      return new Promise((resolve) => setTimeout(() => resolve(), ms))
    },
    async handleBeforeUnload(event) {
      event.preventDefault()
      event.returnValue = ''
    },
  },
}
</script>

<style>
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888888;
  border-radius: 2.5px;
}
.custom-scrollbar::-webkit-scrollbar-corner {
  background: transparent;
}
.sidenav-overlay.show {
  z-index: 1001;
}
</style>
