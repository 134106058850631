const _ = require('lodash')
import Vue from 'vue'
import { DEFAULT_TILES } from '../../constants/app.js'

export default {
  namespaced: true,
  state: {
    date: [],
    speed: 12,
    hovering: [null, null], //save data for 2 map view
    clicking: [null, null],
    hoveringProcessRate: [null, null],
    clickingProcessRate: [null, null],
    numberOfMap: 1,
    mapBounds: null,
    mapBoundsAutoUpdate: [null, null],
    mapTiles: DEFAULT_TILES,
    layerSelected: null,
    selectedGroup: null,
    lockMaps: true,
    refreshTime: 0, // Update and watch for refresh Map's component by Map timer
    conditionRule: [],
    contextMenu: {
      layerCopy: {},
    },
    mapActive: 0,
    datasourceTools: 'frequency',
    scenes: [],
    hoveringClusterGroup: null,
    clickingCLusterGroupMap1: {},
    clickingCLusterGroupMap2: {},
    datasourceOptionActive: {
      datasource: null,
      show: false,
    },
    updatePreviewProcessRate: 0,
    latLonBuildUpdateLayerActive: null,
  },
  mutations: {
    SET_LAT_LON_BUILD_UPDATE_LAYER_ACTIVE(state, payload) {
      state.latLonBuildUpdateLayerActive = payload
    },
    SET_CLICKING_PROCESS_RATE(state, payload) {
      if (payload && (payload.mapIndex == 0 || payload.mapIndex == 1)) {
        if (!payload.data) {
          Vue.set(state.clickingProcessRate, payload.mapIndex, null)
        } else {
          Vue.set(state.clickingProcessRate, payload.mapIndex, [payload.data])
        }
      }
    },
    SET_PROCESS_RATE_OPEN_FILE(state, payload) {
      state.hoveringProcessRate = [null, null]
      state.clickingProcessRate = [null, null]
    },
    UPDATE_PREVIEW_PROCESS_RATE(state, payload) {
      state.updatePreviewProcessRate++
    },
    UPDATE_MAP_BOUNDS_AUTO_UPDATE(state, payload) {
      if (payload && (payload.mapIndex == 0 || payload.mapIndex == 1)) {
        state.mapBoundsAutoUpdate[payload.mapIndex] = payload.data
      }
    },
    SET_DATASOURCE_OPTION_ACTIVE(state, payload) {
      if (payload) {
        state.datasourceOptionActive = payload
      }
    },
    UPDATE_DATASOURCE_OPTION_ACTIVE_DATASOURCE(state, payload) {
      state.datasourceOptionActive.datasource = payload
    },
    UPDATE_DATASOURCE_OPTION_ACTIVE_SHOW(state, payload) {
      state.datasourceOptionActive.show = payload
    },
    CLEAR_CLICKING_CLUSTER_GROUP_MAP(state, payload) {
      Vue.set(state, `clickingCLusterGroupMap1`, {})
      Vue.set(state, `clickingCLusterGroupMap2`, {})
    },
    CLEAR_CLICKING_CLUSTER_GROUP_MAP_WITH_MAPINDEX(state, payload) {
      if (!payload || payload.mapIndex == null) return
      Vue.set(state, `clickingCLusterGroupMap${payload.mapIndex + 1}`, {})
    },
    DELETE_CLICKING_CLUSTER_GROUP_MAP(state, payload) {
      try {
        if (!payload || payload.mapIndex == null || payload.data == null || !payload.data.id) {
          return
        }
        let name = `clickingCLusterGroupMap${payload.mapIndex + 1}`
        let clickingCLusterGroupMapTemp = _.cloneDeep(state[name])
        delete clickingCLusterGroupMapTemp[payload.data.id]
        clickingCLusterGroupMapTemp = clickingCLusterGroupMapTemp && Object.keys(clickingCLusterGroupMapTemp).length ? clickingCLusterGroupMapTemp : {}
        Vue.set(state, name, clickingCLusterGroupMapTemp)
      } catch {}
    },
    SET_CLICKING_CLUSTER_GROUP_MAP(state, payload) {
      if (!payload || payload.mapIndex == null || payload.data == null) {
        return
      }
      Vue.set(state, `clickingCLusterGroupMap${payload.mapIndex + 1}`, payload.data)
    },
    ADD_CLICKING_CLUSTER_GROUP_MAP_1(state, payload) {
      if (!payload || !payload.id) return
      if (payload && !payload.multiShow) {
        state.clickingCLusterGroupMap1 = {}
      }
      Vue.set(state.clickingCLusterGroupMap1, payload.id, payload)
    },
    ADD_CLICKING_CLUSTER_GROUP_MAP_2(state, payload) {
      try {
        if (!payload || !payload.id) return
        if (payload && !payload.multiShow) {
          state.clickingCLusterGroupMap2 = {}
        }
        Vue.set(state.clickingCLusterGroupMap2, payload.id, payload)
      } catch {}
    },
    UPDATE_CLICKING_CLUSTER_GROUP_MAP(state, payload) {
      try {
        if (!payload || payload.mapIndex == null || !payload.data || !payload.data.id) return
        if (payload.mapIndex == 0) {
          Vue.set(state.clickingCLusterGroupMap1, payload.data.id, payload.data)
        }
        if (payload.mapIndex == 1) {
          Vue.set(state.clickingCLusterGroupMap2, payload.data.id, payload.data)
        }
      } catch {}
    },
    SET_HOVERING_CLUSTER_GROUP(state, payload) {
      state.hoveringClusterGroup = payload
    },
    UPDATE_CONDITION_RULE(state, payload) {
      state.conditionRule = _.cloneDeep(payload)
    },
    UPDATE_DATE(state, payload) {
      state.date = payload
    },
    UPDATE_SPEED(state, payload) {
      state.speed = payload
    },
    SET_HOVERING(state, payload) {
      Vue.set(state.hovering, payload.mapIndex, payload.data)
    },
    SET_HOVERING_PROCESS_RATE(state, payload) {
      Vue.set(state.hoveringProcessRate, payload.mapIndex, payload.data)
    },
    SET_CLICKING_OPENFILE(state, value) {
      state.clicking = value
    },
    CLEAR_CLICKING(state) {
      state.clicking = [null, null]
      state.clickingProcessRate = [null, null]
    },
    SET_CLICKING(state, payload) {
      try {
        if (!state.clicking[payload.mapIndex] && payload.data) Vue.set(state.clicking, payload.mapIndex, [payload.data])
        else if (payload.data) {
          let clicking = payload.data
          let clickingMultiShow = !state.clicking[payload.mapIndex] ? [] : state.clicking[payload.mapIndex].filter((popup) => popup.multiShow === true)
          if (clicking.multiShow) {
            let index = !clickingMultiShow ? -1 : _.findIndex(clickingMultiShow, { layerId: clicking.layerId, clickId: clicking.clickId, clickUid: clicking.clickUid })
            if (index < 0) {
              clickingMultiShow.push(clicking)
            }
          } else {
            clickingMultiShow.push(clicking)
          }
          Vue.set(state.clicking, payload.mapIndex, clickingMultiShow)
        }
        if (!payload.data) Vue.set(state.clicking, payload.mapIndex, payload.data)
      } catch {}
    },
    UPDATE_CLICKING(state, payload) {
      Vue.set(state.clicking, payload.mapIndex, payload.data)
    },
    UPDATE_CLICKING_FILTER(state, payload) {
      try {
        if (!payload || payload.mapIndex == null || !payload.data) return
        let clicking = state.clicking[payload.mapIndex]
        if (clicking && clicking.length) {
          for (let i = 0; i < clicking.length; i++) {
            try {
              if (clicking[i].layerId == payload.data.layerId && clicking[i].clickId == payload.data.clickId && clicking[i].clickUid == payload.data.clickUid) {
                clicking[i].latLonSpider = payload.data.latLonSpider
                break
                return
              }
            } catch {}
          }
        }
      } catch {}
    },
    UPDATE_CLICKING_POSITION_PIN(state, payload) {
      let clicking = payload.data
      let index = _.findIndex(state.clicking[payload.mapIndex], { layerId: clicking.layerId, clickId: clicking.clickId, clickUid: clicking.clickUid })
      if (index > -1) {
        state.clicking[payload.mapIndex][index].position = clicking.position
      }
    },
    UPDATE_CLICKING_PIN(state, payload) {
      let clicking = payload.data
      let index = _.findIndex(state.clicking[payload.mapIndex], { layerId: clicking.layerId, clickId: clicking.clickId, clickUid: clicking.clickUid })
      if (index > -1) {
        state.clicking[payload.mapIndex][index].pin = clicking.pin
      }
    },
    UPDATE_CLICKING_CLUSTER(state, payload) {
      try {
        if (payload.mapIndex == null || !payload.data) return
        let clicking = state.clicking[payload.mapIndex]
        for (let i = 0; i < clicking.length; i++) {
          let item = `${clicking[i].layerId}-${clicking[i].clickId}-${clicking[i].clickUid}-${clicking[i].latlng}-${clicking[i].source}`
          if (payload.data.indexOf(item) >= 0) {
            state.clicking[payload.mapIndex][i].clusterOpen = payload.isOpen
          }
        }
      } catch {}
    },
    SET_NUMBER_MAP(state, payload) {
      state.numberOfMap = payload
    },
    SET_MAP_BOUND(state, payload) {
      state.mapBounds = payload
    },
    SET_MAP_TILE(state, { tile, mapIndex }) {
      Vue.set(state.mapTiles, mapIndex, tile)
    },
    SET_MAP_TILES(state, payload) {
      state.mapTiles = payload
    },
    SET_LAYER_SELECTED(state, payload) {
      state.layerSelected = payload
    },

    CLOSE_POPUP(state, payload) {
      let clickingData = null
      if (payload.data.type === 'point' || payload.data.type === 'typhoon') clickingData = state.clicking[payload.mapIndex].filter((popup) => popup.clickId !== payload.data.clickId)
      else clickingData = state.clicking[payload.mapIndex].filter((popup) => popup.clickUid !== payload.data.clickUid)
      Vue.set(state.clicking, payload.mapIndex, clickingData)
    },
    CLOSE_POPUP_PROCESS_RATE(state, payload) {
      try {
        let clickingData = null
        clickingData = state.clickingProcessRate[payload.mapIndex].filter((popup) => !(popup.layerId == payload.data.layerId && popup.clickId == payload.data.clickId))
        Vue.set(state.clickingProcessRate, payload.mapIndex, clickingData)
      } catch {}
    },
    SET_SELECTED_GROUP(state, group) {
      state.selectedGroup = group
    },
    TOGGLE_LOCK_MAPS(state, payload) {
      state.lockMaps = !state.lockMaps
    },
    SCENE_SET_LOCK_MAPS(state, payload) {
      state.lockMaps = payload
    },
    SET_MAP_ACTIVE(state, payload) {
      state.mapActive = payload
    },
    SET_DATASOURCE_TOOLS_ACTIVE(state, value) {
      state.datasourceTools = value
    },
    SET_SCENE(state, value) {
      state.scenes = value
    },
    SAVE_SCENE(state, value) {
      state.scenes.push(value)
    },
    UPDATE_SCENE(state, value) {
      let index = state.scenes.findIndex((i) => i.id === value.id)
      state.scenes[index].name = value.name
    },
    DELETE_SCENE(state, value) {
      let index = state.scenes.findIndex((i) => i.id === value.id)
      state.scenes.splice(index, 1)
    },
    RESET_STATE(state) {
      state.date = []
      state.speed = 12
      state.hovering = [null, null]
      state.clicking = [null, null]
      state.hoveringProcessRate = [null, null]
      state.clickingProcessRate = [null, null]
      state.numberOfMap = 1
      state.mapBounds = null
      state.mapTiles = DEFAULT_TILES
      state.layerSelected = null
      state.selectedGroup = null
      state.lockMaps = true
      state.refreshTime = 0 // Update and watch for refresh Map's component by Map timer
      state.conditionRule = []
      state.datasourceTools = 'frequency'
      state.scenes = []
    },
    UPDATE_CONTEXTMENU(state, payload) {
      let { layerCopy } = payload
      if (layerCopy) state.contextMenu.layerCopy = layerCopy
    },
    UPDATE_POUPUP_FROM_UPDATE_LOCATION_POINT(state, payload) {
      if (payload) {
        try {
          if (state.clicking && state.clicking[0] && state.clicking[0].length) {
            for (let i = 0; i < state.clicking[0].length; i++) {
              let nameTemp = `${state.clicking[0][i].clickId}-${state.clicking[0][i].clickUid}-${state.clicking[0][i].latlng}`
              if (payload[nameTemp]) {
                state.clicking[0][i].latlng = payload[nameTemp].latlonNew
                if (state.clicking[0][i].latLonSpider && payload[nameTemp].latLonSpiderNew) {
                  state.clicking[0][i].latLonSpider = payload[nameTemp].latLonSpiderNew
                }
              }
            }
          }
        } catch {}
        try {
          if (state.clicking && state.clicking[1] && state.clicking[1].length) {
            for (let i = 0; i < state.clicking[0].length; i++) {
              let nameTemp = `${state.clicking[1][i].clickId}-${state.clicking[1][i].clickUid}-${state.clicking[1][i].latlng}`
              if (payload[nameTemp]) {
                state.clicking[1][i].latlng = payload[nameTemp].latlonNew
                if (state.clicking[1][i].latLonSpider && payload[nameTemp].latLonSpiderNew) {
                  state.clicking[1][i].latLonSpider = payload[nameTemp].latLonSpiderNew
                }
              }
            }
          }
        } catch {}
      }
    },
  },
}
