export default {
  namespaced: true,
  state: {
    groupItemsList: [], // { number: '1', layerID: , items: [], type: 'point' }
    selectedGroupItems: null,
  },
  mutations: {
    SET_GROUP_ITEMS_LIST(state, payload) {
      state.groupItemsList = payload
    },
    ADD_GROUP_ITEMS(state, group) {
      const index = state.groupItemsList.findIndex((o) => o.number === group.number)
      if (index !== -1) {
        state.groupItemsList.splice(index, 1)
      }
      state.groupItemsList = [...state.groupItemsList, group]
    },
    REMOVE_GROUP(state, group) {
      const index = state.groupItemsList.findIndex((o) => o.number === group.number && o.layerID === group.layerID)
      if (index !== -1) {
        state.groupItemsList.splice(index, 1)
      }
      try {
        if (state.selectedGroupItems && state.selectedGroupItems.layerID && state.selectedGroupItems.number != null && state.selectedGroupItems.layerID == group.layerID && state.selectedGroupItems.number == group.number) {
          state.selectedGroupItems = null
        }
      } catch {}
    },
    UPDATE_GROUP(state, group) {
      const index = state.groupItemsList.findIndex((o) => o.number === group.number && o.layerID === group.layerID)
      if (index !== -1) {
        state.groupItemsList.splice(index, 1, group)
      }
      try {
        if (state.selectedGroupItems && state.selectedGroupItems.layerID && state.selectedGroupItems.number != null && state.selectedGroupItems.layerID == group.layerID && state.selectedGroupItems.number == group.number) {
          state.selectedGroupItems.items = group.items
        }
      } catch {}
    },
    SET_SELECTED_GROUP_ITEMS(state, payload) {
      state.selectedGroupItems = payload
    },
    RESET_STATE(state) {
      state.groupItemsList = [] // { number: '1', layerID: , items: [], type: 'point' }
      state.selectedGroupItems = null
    },
  },
}
