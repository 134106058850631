const _ = require('lodash')
import Vue from 'vue'
import { scaleLog10, scaleLog10Plus, scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js'
import { splitByLastIndex } from '@/utilities/StringUtility.js'

const statisticsDataKeys = ['Q1', 'Median', 'Q3', 'Lower', 'Upper', 'Outliers']

export async function selectAll(datasourceId, scale, encoding, from, to) {
  scale = scale || 'original'
  let records = {}
  const datasource = Vue.prototype.$db[datasourceId]
  let items = datasource.items

  const dates = datasource.dates
  records['dates'] = dates
  for (const item in items) {
    // Auto calculate scale table if not exist
    if (['log10', 'log10+', 'normalize', 'standardize'].includes(scale)) await generateScale(datasourceId, item, scale)

    // Query data
    const data = datasource[`data_${item}_${scale}`]
    if (!data) {
      Vue.prototype.$db[datasourceId][`data_${item}_${scale}`] = []
    } else {
      records = { ...records, ...data }
    }
  }
  return records
}

// export async function selectAllByLocationsItems(datasourceId, locationsItems, scale) {
//   scale = scale || 'original';
//   let records = {};
//   const groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1]);
//   for (const item in groupByItem) {
//     // Auto calculate scale table if not exist
//     if (['log10', 'log10+', 'normalize', 'standardize'].includes(scale)) await generateScale(datasourceId, item, scale);

//     // Query data
//     const datasource = Vue.prototype.$db[datasourceId];
//     const data = datasource[`data_${item}_${scale}`];
//     // Get Dates (Live mode support)
//     if (scale == 'live') records['dates'] = datasource.datesLive;
//     else records['dates'] = datasource.dates;

//     // Get Data
//     for (let i = 0; i < locationsItems.length; i++) {
//       if (data) if (data[locationsItems[i]]) records[locationsItems[i]] = data[locationsItems[i]];
//     }
//   }
//   return records;
// }
export async function selectAllByLocationsItems(datasourceId, locationsItems, scale) {
  scale = scale || 'original'
  let records = {}
  let groupByItem = {}
  try {
    if (locationsItems && locationsItems.length) {
      for (let i = 0; i < locationsItems.length; i++) {
        if (locationsItems[i] && locationsItems[i].length) {
          for (let k = 0; k < locationsItems[i].length; k++) {
            if (locationsItems[i][k] == '-') {
              let itemTemp = k + 1 < locationsItems[i].length ? locationsItems[i].slice(k + 1) : null
              if (itemTemp) {
                if (!groupByItem[itemTemp]) {
                  groupByItem[itemTemp] = []
                }
                groupByItem[itemTemp].push(locationsItems[i])
              }
            }
          }
        }
      }
    }
  } catch {
    groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1])
  }
  const datasource = Vue.prototype.$db[datasourceId]
  for (const item in groupByItem) {
    // Auto calculate scale table if not exist
    if (['log10', 'log10+', 'normalize', 'standardize'].includes(scale)) await generateScale(datasourceId, item, scale)

    // Query data
    if (datasource) {
      const data = datasource[`data_${item}_${scale}`]
      // Get Dates (Live mode support)
      if (scale == 'live') records['dates'] = datasource.datesLive
      else records['dates'] = datasource.dates
      if (data) {
        // Get Data
        for (let i = 0; i < locationsItems.length; i++) {
          if (data[locationsItems[i]]) records[locationsItems[i]] = data[locationsItems[i]]
        }
      }
    }
  }
  return records
}

// export async function checkLocations(locationsItems, dX, scale) {
//   scale = scale || 'original';
//   const groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1]);
//   let array = [];
//   for (const item in groupByItem) {
//     const a = Vue.prototype.$db[dX];
//     const data = a[`data_${item}_${scale}`];
//     for (let i = 0; i < locationsItems.length; i++) {
//       if (data[locationsItems[i]]) array.push(locationsItems[i]);
//     }
//   }
//   return array;
// }
export async function checkLocations(locationsItems, dX, scale) {
  scale = scale || 'original'
  let array = []
  let groupByItem = {}
  try {
    if (locationsItems && locationsItems.length) {
      for (let i = 0; i < locationsItems.length; i++) {
        if (locationsItems[i] && locationsItems[i].length) {
          for (let k = 0; k < locationsItems[i].length; k++) {
            if (locationsItems[i][k] == '-') {
              let itemTemp = k + 1 < locationsItems[i].length ? locationsItems[i].slice(k + 1) : null
              if (itemTemp) {
                if (!groupByItem[itemTemp]) {
                  groupByItem[itemTemp] = []
                }
                groupByItem[itemTemp].push(locationsItems[i])
              }
            }
          }
        }
      }
    }
  } catch {
    groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1])
  }

  for (const item in groupByItem) {
    const a = Vue.prototype.$db[dX]
    const data = a[`data_${item}_${scale}`]
    for (let i = 0; i < locationsItems.length; i++) {
      if (data[locationsItems[i]]) array.push(locationsItems[i])
    }
  }
  return array
}

// export async function selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, scale) {
//   scale = scale || 'original';
//   let records = {};
//   const groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1]);
//   for (const item in groupByItem) {
//     // Auto calculate scale table if not exist
//     if (['log10', 'log10+', 'normalize', 'standardize'].includes(scale)) await generateScale(datasourceId, item, scale);

//     // Query data
//     const datasource = Vue.prototype.$db[datasourceId];
//     const dates = datasource.dates;
//     // Get Date-Range index
//     const startDateIndex = [...dates].findIndex((d) => d >= minDate) === -1 ? 0 : dates.findIndex((d) => d >= minDate);
//     const validIdx = [...dates].reverse().findIndex((d) => d <= maxDate);
//     if (validIdx === -1) break;
//     let endDateIndex = [...dates].length - validIdx;
//     records['dates'] = dates.slice(startDateIndex, endDateIndex);
//     const data = datasource[`data_${item}_${scale}`];
//     for (let i = 0; i < locationsItems.length; i++) {
//       if (data[locationsItems[i]]) records[locationsItems[i]] = data[locationsItems[i]].slice(startDateIndex, endDateIndex);
//     }
//   }
//   return records;
// }
export async function selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, scale) {
  scale = scale || 'original'
  let records = {}
  let groupByItem = {}
  try {
    if (locationsItems && locationsItems.length) {
      for (let i = 0; i < locationsItems.length; i++) {
        if (locationsItems[i] && locationsItems[i].length) {
          for (let k = 0; k < locationsItems[i].length; k++) {
            if (locationsItems[i][k] == '-') {
              let itemTemp = k + 1 < locationsItems[i].length ? locationsItems[i].slice(k + 1) : null
              if (itemTemp) {
                if (!groupByItem[itemTemp]) {
                  groupByItem[itemTemp] = []
                }
                groupByItem[itemTemp].push(locationsItems[i])
              }
            }
          }
        }
      }
    }
  } catch {
    groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1])
  }
  const datasource = Vue.prototype.$db[datasourceId]
  for (const item in groupByItem) {
    // Auto calculate scale table if not exist
    if (['log10', 'log10+', 'normalize', 'standardize'].includes(scale)) await generateScale(datasourceId, item, scale)

    // Query data
    const dates = datasource.dates
    // Get Date-Range index
    const startDateIndex = [...dates].findIndex((d) => d >= minDate) === -1 ? 0 : dates.findIndex((d) => d >= minDate)
    const validIdx = [...dates].reverse().findIndex((d) => d <= maxDate)
    if (validIdx === -1) break
    let endDateIndex = [...dates].length - validIdx
    records['dates'] = dates.slice(startDateIndex, endDateIndex)
    const data = datasource[`data_${item}_${scale}`]
    if (data) {
      for (let i = 0; i < locationsItems.length; i++) {
        if (data[locationsItems[i]]) records[locationsItems[i]] = data[locationsItems[i]].slice(startDateIndex, endDateIndex)
      }
    }
  }
  return records
}

export async function selectDateByItem(datasourceId, item, date, scale) {
  let records = {}
  scale = scale || 'original'
  if (['log10', 'log10+', 'normalize', 'standardize'].includes(scale)) await generateScale(datasourceId, item, scale)
  const datasource = Vue.prototype.$db[datasourceId]
  const data = datasource[`data_${item}_${scale}`]
  let indexDate = null
  for (let i = datasource.dates.length - 1; i >= 0; i--) {
    if (datasource.dates[i] <= date) {
      indexDate = i
      break
    }
  }
  for (const locationItem in data) {
    if (data[locationItem][indexDate] != null) records[locationItem] = data[locationItem][indexDate]
    // Exact match
    else {
      let frontFillIdx = indexDate - 1
      while (!data[locationItem][frontFillIdx] && frontFillIdx > 0) {
        frontFillIdx--
      }
      records[locationItem] = data[locationItem][frontFillIdx] // First existed value
    }
  }
  return records
}

export async function getAllPoints(table) {
  return Vue.prototype.$db[table].data.slice()
}

export async function getAllTyphoons(table) {
  return Vue.prototype.$db[table].data.slice()
}

export async function getGeojson(table) {
  return Vue.prototype.$db[table].data[0].data
}

export async function getImage(table) {
  return Vue.prototype.$db[table].data[0].data
}

/** Generate scale for a datasource */
// async function generateScale(datasource, item, scale) {
//   let data = _.cloneDeep(Vue.prototype.$db[datasource][`data_${item}_original`]);
//   for (const key in data) {
//     if (scale === 'log10') {
//       data[key] = scaleLog10(data[key]);
//     } else if (scale === 'log10+') {
//       data[key] = scaleLog10Plus(data[key]);
//     } else if (scale === 'normalize') {
//       data[key] = scaleNormalize(data[key]);
//     } else if (scale === 'standardize') {
//       data[key] = scaleStandardize(data[key]);
//     }
//   }
//   Vue.prototype.$db[datasource][`data_${item}_${scale}`] = data;
// }
async function generateScale(datasource, item, scale) {
  let data = Vue.prototype.$db && Vue.prototype.$db[datasource] && Vue.prototype.$db[datasource][`data_${item}_original`] ? _.cloneDeep(Vue.prototype.$db[datasource][`data_${item}_original`]) : null
  //   let data = _.cloneDeep(Vue.prototype.$db[datasource][`data_${item}_original`])
  if (data) {
    for (const key in data) {
      if (scale === 'log10') {
        data[key] = scaleLog10(data[key])
      } else if (scale === 'log10+') {
        data[key] = scaleLog10Plus(data[key])
      } else if (scale === 'normalize') {
        data[key] = scaleNormalize(data[key])
      } else if (scale === 'standardize') {
        data[key] = scaleStandardize(data[key])
      }
    }
    Vue.prototype.$db[datasource][`data_${item}_${scale}`] = data
  }
}
