import Encoding from 'encoding-japanese'

/** Split string by character at last index it appear
 * @return Array have fist and last string splited */
export function splitByLastIndex(string, sep) {
  var arrVars = string.split(sep);
  var right = arrVars.pop();
  var left = arrVars.join(sep);
  return [left, right];
}

/** Convert a stream to string
 * @return a Promise with result as utf8 string */
export function streamToString(stream) {
  return new Promise((resolve, reject) => {
    const chunks = [];
    stream.on("data", (chunk) => chunks.push(chunk));
    stream.on("error", reject);
    stream.on("end", () => {
      const buffer = Buffer.concat(chunks);
      const utf8Buffer = Encoding.convert(buffer, 'UTF8'); // Convert to UTF-8
      const string = Buffer.from(utf8Buffer).toString();
      resolve(string);
    });
  });
}