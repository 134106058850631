export const modulesExportFormatDatePlotyly = {
  moduleType: 'locale',
  name: 'en',
  dictionary: {
    'Click to enter Colorscale title': 'Click to enter Colourscale title',
  },
  format: {
    days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    periods: ['AM', 'PM'],
    dateTime: '%a %b %e %X %Y',
    date: '%Y/%m/%d',
    time: '%H:%M:%S',
    decimal: '.',
    thousands: ',',
    grouping: [3],
    currency: ['$', ''],
    year: '%Y',
    month: '%Y/%m',
    dayMonth: '%m/%d',
    dayMonthYear: '%Y/%m/%d',
  },
}