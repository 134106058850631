import { OPTION_PLOTLY_PALETTE } from './colors'
export const APP_NAME = 'Ecoplot Pro'

export const MAP_TILES = [
  {
    name: 'light_map',
    url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 20,
    image: 'map/light',
  },
  {
    name: 'dark_map',
    url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 20,
    image: 'map/dark',
  },
  {
    name: 'OpenStreetMap',
    url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    // attribution: '国土地理院',
    image: 'map/OpenStreetMap',
    maxZoom: 20,
  },
  {
    name: 'vegehill_map',
    url: 'https://map.ecoris.info/tiles/vegehill/{z}/{x}/{y}.png',
    attribution: '国土地理院',
    image: 'map/vegehill',
    maxZoom: 15,
  },
  {
    name: 'electronic_map',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
    // attribution: '国土地理院',
    image: 'map/electronic-topographic-map',
    maxZoom: 18,
  },
  {
    name: 'shoreline_map',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
    // attribution: '国土地理院',
    image: 'map/shoreline',
    maxZoom: 18,
  },
  {
    name: 'standard_map',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/english/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
    // attribution: '国土地理院',
    image: 'map/electronic-topographic-map-en',
    maxZoom: 11,
  },
  {
    name: 'relief_map',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/relief/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
    // attribution: '国土地理院',
    image: 'map/map_relief',
    maxZoom: 15,
    minZoom: 5,
  },
  {
    name: 'shaded_relief_map',
    url: 'https://cyberjapandata.gsi.go.jp/xyz/hillshademap/{z}/{x}/{y}.png',
    attribution: '&copy; <a href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
    // attribution: '国土地理院',
    image: 'map/shaded_relief_map',
    maxZoom: 16,
  },
  {
    name: 'Imagery',
    url: 'Imagery',
    attribution: '&copy; <a href="https://developers.arcgis.com/rest/basemap-styles/">basemap</a>',
    image: 'map/esri-mapbase/Imagery',
    minZoom: 1,
    maxZoom: 19,
    category: 'esri-basemaplayer',
  },
]

export const DEFAULT_TILES = [
  {
    name: 'light_map',
    url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 20,
    image: 'map/light',
  },
  {
    name: 'light_map',
    url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
    subdomains: 'abcd',
    maxZoom: 20,
    image: 'map/light',
  },
]

export const TILE_DARK = {
  name: 'dark_map',
  url: 'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png',
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  subdomains: 'abcd',
  maxZoom: 20,
  image: 'map/dark',
}

export const TILE_LIGHT = {
  name: 'light_map',
  url: 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png',
  attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
  subdomains: 'abcd',
  maxZoom: 20,
  image: 'map/light',
}

export const DEFAULT_TABS = {
  timeseries: {
    datasourceSelected: [],
    typeSelected: 'lines',
    scale: null,
    date: [],
    selectedGroup: null,
    autoGroup: null,
    selectedLayout: '1x0',
    sizeWidth: {
      size: 5,
      width: 1,
      default: false,
    },
    drawBackground: true,
    arrayDatasourceSelect: [
      { date: [], datasourceSelected: [], visible: true, id: 'xy1', xaxis: 'x1', yaxis: 'y1', autorange: true, typeSelected: 'lines', colors: null, sizeWidth: { size: 5, width: 1, default: false }, resample: { value: 1, max: 1, autoGroup: true, autoResample: false }, fillData: null, netcdfDataChart: null, netcdfMetadata: {}, chartLayout: {} },
      { date: [], datasourceSelected: [], visible: false, id: 'xy2', xaxis: 'x2', yaxis: 'y2', autorange: true, typeSelected: 'lines', colors: null, sizeWidth: { size: 5, width: 1, default: false }, resample: { value: 1, max: 1, autoGroup: true, autoResample: false }, fillData: null, netcdfDataChart: null, netcdfMetadata: {}, chartLayout: {} },
      { date: [], datasourceSelected: [], visible: false, id: 'xy3', xaxis: 'x3', yaxis: 'y3', autorange: true, typeSelected: 'lines', colors: null, sizeWidth: { size: 5, width: 1, default: false }, resample: { value: 1, max: 1, autoGroup: true, autoResample: false }, fillData: null, netcdfDataChart: null, netcdfMetadata: {}, chartLayout: {} },
      { date: [], datasourceSelected: [], visible: false, id: 'xy4', xaxis: 'x4', yaxis: 'y4', autorange: true, typeSelected: 'lines', colors: null, sizeWidth: { size: 5, width: 1, default: false }, resample: { value: 1, max: 1, autoGroup: true, autoResample: false }, fillData: null, netcdfDataChart: null, netcdfMetadata: {}, chartLayout: {} },
    ],
    selectTimeseries: 'xy1',
    sizeWidth: {
      size: 5,
      width: 1,
      default: true,
    },
    resample: {
      value: 1,
      max: 1,
      autoGroup: true,
      autoResample: false,
    },
    fillData: null,
    legend: true,
    year: false,
    editable: true,
    colors: null,
    redrawNetcdfData: 0,
    netcdfStatus: null,
    netcdfItemSelectedTemp: {
      gridOption: [],
      gridSelected: [],
      item: null,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  bar: {
    datasourceSelected: [],
    typeSelected: 'group',
    scale: null,
    date: [],
    selectedGroup: null,
    editable: true,
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  bar_uplot: {
    datasourceSelected: [],
    typeSelected: 'group',
    scale: null,
    date: [],
    selectedGroup: null,
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  timeseries_uplot: {
    datasourceSelected: [],
    typeSelected: 'lines',
    scale: null,
    date: [],
    selectedGroup: null,
    autoGroup: null,
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  scatter: {
    datasourceSelected: [],
    scale: null,
    date: [],
    selectedGroup: null,
    colors: null,
    symbolSelected: ['0', '1', '5', '4'],
    sizeWidth: {
      default: false,
      range: [5, 30],
    },
    legend: true,
    year: false,
    editable: true,
    outlineGroup: {
      default: false,
      width: 2,
      color: 'rgb(140 138 138)',
      opacity: 0.5,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  scatter_uplot: {
    datasourceSelected: [],
    scale: null,
    date: [],
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  scatter_d3: {
    datasourceSelected: [],
    scale: null,
    date: [],
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  scatter_three: {
    colors: null,
    datasourceSelected: [],
    scale: null,
    date: [],
    sizeWidth: {
      default: false,
      range: [5, 30],
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  boxplot: {
    datasourceSelected: [],
    scale: null,
    date: [],
    colors: null,
    selectedGroup: null,
    autoGroup: null,
    rotate: false,
    summary: true,
    legend: true,
    year: false,
    editable: true,
    underlying: false,
    netcdfDataChart: null,
    netcdfMetadata: {},
    netcdfStatus: null,
    redrawNetcdfData: 0,
    netcdfItemSelectedTemp: {
      gridOption: [],
      gridSelected: [],
      item: null,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  boxplot_uplot: {
    datasourceSelected: [],
    scale: null,
    date: [],
    selectedGroup: null,
    autoGroup: null,
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  histogram: {
    datasourceSelected: [],
    typeSelected: 'group',
    scale: null,
    date: [],
    colors: null,
    selectedGroup: null,
    autoGroup: null,
    legend: true,
    year: false,
    rotate: false,
    editable: true,
    netcdfDataChart: null,
    netcdfMetadata: {},
    netcdfStatus: null,
    redrawNetcdfData: 0,
    netcdfItemSelectedTemp: {
      gridOption: [],
      gridSelected: [],
      item: null,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  histogram_uplot: {
    datasourceSelected: [],
    typeSelected: 'group',
    scale: null,
    date: [],
    selectedGroup: null,
    autoGroup: null,
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  decomposition: {
    datasourceSelected: [],
    mode: 'loess',
    frequency: 2,
    date: [],
    netcdfDataChart: null,
    netcdfMetadata: {},
    netcdfStatus: null,
    redrawNetcdfData: 0,
    netcdfItemSelectedTemp: {
      gridOption: [],
      gridSelected: [],
      item: null,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  seasonal_analytics: {
    datasourceSelected: [],
    typeSelected: 'lines',
    sizeWidth: {
      size: 5,
      width: 1,
      default: false,
    },
    legend: true,
    year: false,
    editable: true,
    colorsCycle: null,
    colorsUnit: null,
    colorsUnitBoxplot: null,
    resampleSeasonal: 'monthlyAverage',
    mode: 'loess',
    frequency: 2,
    date: [],
    netcdfDataChart: null,
    netcdfMetadata: {},
    netcdfStatus: null,
    redrawNetcdfData: 0,
    netcdfItemSelectedTemp: {
      gridOption: [],
      gridSelected: [],
      item: null,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  correlation: {
    datasourceSelected: [],
    date: [],
    chart: null,
    method: 'pearsonr',
    selectedGroup: null,
    autoGroup: null,
    autoScale: null,
    netcdfDataChart: null,
    netcdfMetadata: {},
    netcdfStatus: null,
    redrawNetcdfData: 0,
    netcdfItemSelectedTemp: {
      gridOption: [],
      gridSelected: [],
      item: null,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  machine_learning: {
    datasourceSelected: [],
    modelType: 'UL',
    featureItems: [],
    epoch: 5,
    lookBack: 24,
    predictFuture: 36,
    batchSize: 4,
    activation: 'linear',
    optimizer: 'adam',
    lossFunction: 'meanSquaredError',
    learningRate: 0.001,
    naAction: 'bfill',
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayoutTrain: {},
    chartLayoutPredict: {},
  },
  lines: {
    typeSelected: 'lines',
    datasourceSelected: [],
    scale: null,
    date: [],
    colors: null,
    selectedGroup: null,
    sizeWidth: {
      size: 10,
      width: 2,
      default: false,
    },
    symbolSelected: ['0', '1', '5', '4'],
    legend: true,
    year: false,
    rotate: false,
    editable: true,
    outlineGroup: {
      default: false,
      width: 2,
      color: 'rgb(140 138 138)',
      opacity: 0.5,
    },
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  lines_uplot: {
    typeSelected: 'lines',
    datasourceSelected: [],
    scale: null,
    date: [],
    selectedGroup: null,
    chartFontFamily: 'Noto Sans JP',
    chartTextSize: 14,
    chartTextColor: null,
    chartLayout: {},
  },
  datasouce_tools: {
    outlier: {
      chartFontFamily: 'Noto Sans JP',
      chartTextSize: 14,
      chartTextColor: null,
      chartLayout: {},
    },
  },
}
let currentAppVersion = '1.2.7'
try {
  currentAppVersion = require('../../package.json').version
} catch {}

export const APP_CONFIG = {
  version: currentAppVersion,
  appId: `besec.co.jp.ecoplot-pro.v.${currentAppVersion}`,
}
