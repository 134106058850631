const _ = require('lodash');
import Dexie from 'dexie';
import { v4 as uuidv4 } from 'uuid';
import { scaleLog10, scaleLog10Plus, scaleNormalize, scaleStandardize } from '@/utilities/NumberUtility.js';
import { splitByLastIndex } from '@/utilities/StringUtility.js';

export const db = new Dexie('ED-' + uuidv4());
db.version(1).stores({});
export const STRUCTURES = {
  point: '++id',
  timeseries: '++id,date',
  image: '++id',
  multi: '++id',
  typhoon: '++id,date',
};

export async function addTable(name, structure, data) {
  db.close();
  db.version(Math.round(db.verno + 1)).stores({ [name]: structure });
  await db.open();
  await db[name].bulkAdd(data);
}

export async function deleteTables(names) {
  db.close();
  let schema = Object.fromEntries(names.map((name) => [name, null]));
  db.version(Math.round(db.verno + 1)).stores(schema);
  await db.open();
}

export async function selectAllByLocationsItems(datasourceId, locationsItems, scale) {
  scale = scale || 'original';
  let records = [];
  let groupByItem = {};
  try {
    if (locationsItems && locationsItems.length) {
      for (let i = 0; i < locationsItems.length; i++) {
        if (locationsItems[i] && locationsItems[i].length) {
          for (let k = 0; k < locationsItems[i].length; k++) {
            if (locationsItems[i][k] == '-') {
              let itemTemp = k + 1 < locationsItems[i].length ? locationsItems[i].slice(k + 1) : null;
              if (itemTemp) {
                if (!groupByItem[itemTemp]) {
                  groupByItem[itemTemp] = [];
                }
                groupByItem[itemTemp].push(locationsItems[i]);
              }
            }
          }
        }
      }
    }
  } catch {
    groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1]);
  }
  // const groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1]);

  for (const item in groupByItem) {
    const table = datasourceId + '_' + item;

    // Auto calculate scale table if not exist
    if (['log10', 'normalize', 'standardize'].includes(scale)) await generateScale(table, scale);

    // Get list of table name
    let names = db.tables.map((t) => t.name).filter((name) => name.startsWith(`${table}_${scale}`));
    names.sort(); // sort by year

    const locations = _.uniq(groupByItem[item].map((i) => splitByLastIndex(i, '-')[0]));
    for (const name of names) {
      await db[name].each((record) => {
        // use "each" instead of "toArray", bug: Maximum IPC message size exceeded
        // TODO: bad performance! Create data object by locations
        // records.push(record);
        for (const location in record) {
          if (locations.includes(location)) records.push({ LocationItem: location + '-' + item, Date: record.date, Value: record[location] });
        }
      });
    }
  }
  return records;
}

export async function selectRangeByLocationsItems(datasourceId, locationsItems, minDate, maxDate, scale) {
  scale = scale || 'original';
  let records = [];
  let groupByItem = {};
  try {
    if (locationsItems && locationsItems.length) {
      for (let i = 0; i < locationsItems.length; i++) {
        if (locationsItems[i] && locationsItems[i].length) {
          for (let k = 0; k < locationsItems[i].length; k++) {
            if (locationsItems[i][k] == '-') {
              let itemTemp = k + 1 < locationsItems[i].length ? locationsItems[i].slice(k + 1) : null;
              if (itemTemp) {
                if (!groupByItem[itemTemp]) {
                  groupByItem[itemTemp] = [];
                }
                groupByItem[itemTemp].push(locationsItems[i]);
              }
            }
          }
        }
      }
    }
  } catch {
    groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1]);
  }
  // const groupByItem = _.groupBy(locationsItems, (i) => splitByLastIndex(i, '-')[1]);

  for (const item in groupByItem) {
    const table = datasourceId + '_' + item;

    // Auto calculate scale table if not exist
    if (['log10', 'normalize', 'standardize'].includes(scale)) await generateScale(table, scale);

    // Get list of table name
    let names = db.tables.map((t) => t.name).filter((name) => name.startsWith(`${table}_${scale}`));
    names.sort(); // sort by year

    // filter table name that between minDate and maxDate
    names = names.filter((name) => {
      const tableYear = +name.substring(name.length - 4);
      const minYear = +minDate.substring(0, 4);
      const maxYear = +maxDate.substring(0, 4);
      return tableYear >= minYear && tableYear <= maxYear;
    });

    const locations = _.uniq(groupByItem[item].map((i) => splitByLastIndex(i, '-')[0]));
    for (const name of names) {
      await db[name]
        .where('date')
        .between(minDate, maxDate, true, true)
        .each((record) => {
          // use "each" instead of "toArray", bug: Maximum IPC message size exceeded
          // TODO: bad performance! Create data object by locations
          // records.push(record);
          for (const location in record) {
            if (locations.includes(location)) records.push({ LocationItem: location + '-' + item, Date: record.date, Value: record[location] });
          }
        });
    }
  }
  return records;
}

export async function selectDateByItem(datasourceId, item, date) {
  let result = null;
  let records = [];
  const table = datasourceId + '_' + item;

  // get list of table name
  let names = db.tables.map((t) => t.name).filter((name) => name.startsWith(`${table}_original`));

  // sort by year
  names.sort();

  // filter table name that below the date param
  names = names.filter((name) => {
    const tableYear = +name.substring(name.length - 4);
    const paramYear = +date.substring(0, 4);
    return tableYear <= paramYear;
  });

  for (let i = names.length - 1; i >= 0; i--) {
    const data = await db[names[i]].where('date').belowOrEqual(date).last();
    if (data) {
      result = data;
      break;
    }
  }

  // Convert to object array (New Format)
  for (const location in result) {
    if (location !== 'date') records.push({ LocationItem: location + '-' + item, Date: result.date, Value: result[location] });
  }
  return records;
}

async function generateScale(table, scale) {
  if (db.tables.map((t) => t.name).some((name) => name.startsWith(`${table}_${scale}`))) return;

  let dataObj = {};
  let dateArr = [];

  // get list of original table name
  let names = db.tables.map((t) => t.name).filter((name) => name.startsWith(`${table}_original`));

  // sort by year
  names.sort();

  for (const name of names) {
    await db[name].each((r) => {
      dateArr.push(r.date);
      _.mergeWith(
        dataObj,
        _.mapValues(
          _.pickBy(r, (_v, k) => k !== 'id' && k !== 'date'),
          (v, _k) => [v]
        ),
        (objValue, srcValue) => {
          if (_.isArray(objValue)) return objValue.concat(srcValue);
        }
      );
    });
  }

  for (const key in dataObj) {
    if (scale === 'log10') {
      dataObj[key] = scaleLog10(dataObj[key]);
    } else if (scale === 'log10+') {
      dataObj[key] = scaleLog10Plus(dataObj[key]);
    } else if (scale === 'normalize') {
      dataObj[key] = scaleNormalize(dataObj[key]);
    } else if (scale === 'standardize') {
      dataObj[key] = scaleStandardize(dataObj[key]);
    }
  }

  const yearMap = _.groupBy(
    dateArr.map((d, i) => [d, i]),
    (v) => new Date(v[0]).getFullYear()
  );
  for (const year in yearMap) {
    let records = [];
    for (const [date, index] of yearMap[year]) {
      records.push({ date, ..._.mapValues(dataObj, (v, _k) => v[index]) });
    }
    await addTable(`${table}_${scale}_${year}`, STRUCTURES.timeseries, []);
    await db[`${table}_${scale}_${year}`].bulkAdd(records);
  }
}
