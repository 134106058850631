import Vue from 'vue'
import 'setimmediate'
const { createLogger, format, transports } = require('winston')
const { combine, timestamp, printf } = format

export const logger = {
  created() {
    //check save file logs in RAM , if empty then create file
    if (!Vue.prototype.$logs) Vue.prototype.$logs = []

    // định dạng data log
    this.formatLog = printf(({ level, message, timestamp, duration = undefined, layerId = undefined, id = 0 }) => {
      this.$logs.push({ Level: level, Project: this.projectName, Time: timestamp, Messages: message, LayerId: layerId, Duration: duration, Id: id })
      return []
    })
    try {
      this.log = createLogger({
        format: combine(timestamp({ format: 'YYYY-MM-DD HH:mm:ss' }), this.formatLog),
        transports: [new transports.Console()],
      })
    } catch (error) {}
  },
}
